import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter07 from '../common/src/assets/image/charity/specter/kapitola_07_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Siedma kapitola: Zničenie rodiny | Ako duch komunizmu vládne nášmu svetu"
           description="Siedma kapitola: Zničenie rodiny"
           image={imageChapter07}/>
      <h1>Siedma kapitola: Zničenie rodiny</h1>
      <h2>Úvod</h2>
      <p>Rodina je stavebným kameňom ľudskej spoločnosti a umožňuje ľuďom nielen vychovávať deti v stabilnom a
        starostlivom prostredí, ale aj odovzdávať vedomosti z jednej generácie na ďalšiu. Manželstvo je posvätná
        inštitúcia usporiadaná nebesami pre ľudstvo, aby vytváralo rodiny a chránilo tradičné dedičstvo a morálku.</p>
      <p>Dnes je tradičná rodina pomaly ničená. Diela Karla Marxa a ďalších komunistov popisujú rodinu ako formu
        súkromného vlastníctva, ktorá sa má zrušiť. Komunistické režimy okrem prenasledovania náboženstva a duchovnej
        viery kladú lásku ku komunistickej strane nad lásku&nbsp; k rodičom, manželom alebo deťom a povzbudzujú ľudí,
        aby bojovali proti svojej vlastnej rodine.</p>
      <p>Od šesťdesiatych rokov 20. storočia sa na západe objavil rad hnutí proti tradíciám, vrátane moderného
        feminizmu, sexuálneho oslobodenia a práv homosexuálov. Inštitúcia rodiny bola zasiahnutá najťažšie. Pod rúškom
        rovnosti a emancipácie – implicitne a explicitne podporovaných modernými zákonmi, školskými osnovami,
        akademickou teóriou a hospodárskou politikou – tieto hnutia prekrúcajú tradičné väzby medzi pohlaviami, kazia
        deti a privádzajú ľudské správanie na nízku úroveň. Tento trend sa objavil na začiatku 19. storočia a je hlboko
        naplnený komunistickými ideologickými prvkami. Friedrich Engels dúfal, že nakoniec dôjde k „neobmedzenému
        sexuálnemu styku“, ktorý znamená zrušenie tradičného manželstva a úplné odstránenie inštitútu rodiny.<a
          href="#_edn1" id="_ednref1">[1]</a></p>
      <p>Komunizmus vyniká v klamstvách a nepretržitých mutáciách, čo viedlo k neustálemu zmätku, čo ľudia presne
        podporujú, keď podporujú programy a ideológie komunizmu. Postupom času prijímajú hlavné myšlienky komunizmu.
        Tragická situácia dnes – degradácia tradičnej rodiny a zmätok ľudí o skutočnej povahe tohto trendu – je
        výsledkom starostlivého plánovania a postupnej realizácie komunizmu za posledných dvesto rokov.</p>
      <p>Zákony, ktoré boli prijaté v Spojených štátoch a ďalších krajinách, spôsobili množstvo rozvodov a rozbitých
        rodín. V päťdesiatych rokoch asi 11 % amerických detí narodených v manželskej rodine zažilo rozvod rodičov; do
        roku 1970 toto číslo stúplo na 50 %.<a href="#_edn2" id="_ednref2">[2]</a> V roku 1956 sa podľa amerického
        centra pre kontrolu a prevenciu chorôb narodilo v Spojených štátoch menej ako 5 % novorodencov mimo manželstva.
        V roku 2016 sa to číslo blížilo k 40 %.<a href="#_edn3" id="_ednref3">[3]</a> <a href="#_edn4"
                                                                                         id="_ednref4">[4]</a></p>
      <p>V tradičných spoločnostiach na Východe i na Západe sa cudnosť vo vzťahu medzi mužom a ženou považovala za
        cnosť. Dnes sa to považuje za niečo staromódne a represívne. Predmanželský sex a homosexualita, ktoré sa v
        tradičných spoločnostiach považovali za hanebné a úchylné po tisíce rokov, sú nielen čoraz viac považované za
        normálne, ale niekedy ich dokonca aj mlčky alebo výslovne povzbudzujú dnešní pedagógovia a štátny školský
        systém. Deti sú&nbsp; vystavené nadmernej sexualite, deviantným sexuálnym konceptom a pornografii už v ranom
        veku. Za súčasného stavu sa komunistický cieľ zničiť rodinu stane realitou ešte predtým, než splní svoj
        nebezpečný prísľub beztriednej spoločnosti. Zničenie rodiny, základnej jednotky spoločenskej stability, tiež
        znamená zničenie tradičnej morálky stanovenej nebesami a úlohy, ktorú rodina zohráva pri výchove ďalšej
        generácie v rámci tradičnej kultúry.</p>
      <h2>1. Cieľom komunizmu je odstrániť tradičnú rodinu</h2>
      <p>V tradičných kultúrach Východu a Západu bolo manželstvo ustanovené nebesami a považovalo sa za nebeské
        usporiadanie. Po vytvorení tohto zväzku ho nebolo možné rozbiť. Ako muži, tak ženy boli stvorení bohom k božiemu
        obrazu a sú si pred bohom rovní. Boh zároveň vytvoril muža a ženu odlišných a stanovil im rôzne úlohy v rodine a
        v spoločnosti.</p>
      <p>Vo východnej tradičnej kultúre je yang tradične spájaný s mužským princípom, pričom tu je symbolické prepojenie
        so slnkom a nebom. To od mužov vyžaduje, aby sa neustále usilovali o pokrok a niesli zodpovednosť za rodinu. So
        ženami je spätý princíp Yin, pričom tu ide o princíp prepojenia so zemou, čo znamená, že ženy rodia
        a&nbsp;starajú sa o&nbsp;všetko s veľkou cnosťou. Ženy by mali byť prispôsobivé a ohľaduplné k druhým a ich
        povinnosťou je podporovať svojich manželov a vychovávať svoje deti. Iba ak muži a ženy dobre plnia svoje
        príslušné úlohy, môže byť yin a yang v harmónii a deti budú rásť a vyvíjať sa zdravým spôsobom.</p>
      <p>V západnej náboženskej viere je žena kosťou z mužových kostí a&nbsp;je telom z jeho tela.<a href="#_edn5"
                                                                                                     id="_ednref5">[5]</a> Muž
        musí svoju ženu milovať, ako by bola časťou jeho tela, a v prípade potreby sa musí obetovať, aby svoju ženu
        chránil. Žena by potom mala so svojím manželom spolupracovať a pomáhať mu, aby z páru vznikol jeden
        neoddeliteľný celok. Muži tvrdo pracujú, aby svoju rodinu uživili, zatiaľ čo ženy trpia pri pôrode. To všetko
        pramení z rôznych prvotných hriechov, ktoré ľudia nosia.</p>
      <p>Nič z toho neznamená, že muži sú lepší ako ženy, pokiaľ ide o schopnosti alebo inteligenciu, pretože talent
        mužov a žien sa prejavuje v ich rôznych schopnostiach. Pokusy o odstránenie rozdielov medzi pohlaviami sú v
        rozpore so&nbsp; zdravým rozumom a bránia tak mužom, ako aj ženám, aby naplnili svoj potenciál.</p>
      <p>Rodiny zohrávajú svoju úlohu pri odovzdávaní viery a morálky, čím udržiavajú stabilnú a zdravú spoločnosť.
        Rodičia sú deťom prvými učiteľmi. Ak sa deti dokážu naučiť tradičné cnosti, ako nesebeckosť, pokora, vďačnosť,
        vytrvalosť a ďalšie zo slov a skutkov svojich rodičov, budú z&nbsp;toho mať úžitok po zvyšok svojho života.</p>
      <p>Tradičné manželstvo pomáha mužom a ženám spoločne rásť čo sa týka vzájomného morálneho správania sa, pretože to
        vyžaduje, aby manželia a manželky miernili svoje emócie a túžby a jednali navzájom s úctou a toleranciou.
        Manželstvo sa zásadne líši od ľahkovážnej romantickej lásky. Ľudské emócie sú nestále; vzťah, ktorý je možné
        vytvoriť a rozdeliť podľa vlastnej ľubovôle, sa dá len ťažko odlíšiť od spoločnej známosti.</p>
      <p>Avšak podľa komunizmu je rodinná jednotka prekážkou ľudského oslobodenia. Klasický komunizmus považuje
        ekonomické faktory za kľúčové pri určovaní formovania rodinných vzťahov a&nbsp;vyžaduje, aby sa súkromná rodinná
        jednotka zmenila na formu verejného vlastníctva.</p>
      <p>„Oslobodenie ľudstva“ je obrovský klam, ktorý leží v centre komunistickej ideológie. Podľa&nbsp; komunistického
        myslenia je útlak nie iba ekonomický alebo sociálny, ale je zakorenený v samotnej kultúre spoločnosti.
        „Oslobodenie“ pre komunistov znamená zničenie kultúrnych noriem „zavedených“ tradičnou spoločenskou morálkou.
        Podľa ich názoru patriarchát tradičnej štruktúry rodiny utláča ženy a tradičná etika medzi mužom a ženou potláča
        ľudskú povahu.</p>
      <p>Súčasné teórie odvodené z marxizmu, zmiešané s freudovskými koncepciami, kladú sexuálnu túžbu do centra otázok
        spojených s rodinou. Spoločnou charakteristikou týchto dvoch ideológií je odmietnutie základnej ľudskej morálky
        a uctievanie materializmu a túžby.</p>
      <h2>2. Komunizmus podporuje promiskuitu</h2>
      <p>Jedným z Marxových ideologických predchodcov bol waleský socialista Robert Owen, známy svojím pokusom z roku
        1825 realizovať svoju víziu „utopickej“ spoločnosti v New Harmony v Indiane. V roku 1826 Owen povedal:</p>
      <p><em>Teraz vám a svetu vyhlasujem, že človek, až doteraz, bol vo všetkých častiach sveta, otrokom Trojice
        najhorších ziel, aké si viete predstaviť, ktoré spôsobujú duševné a fyzické zlo celej ľudskej rase. Mienim tým
        súkromný majetok čiže majetok jednotlivca – absurdné a iracionálne náboženské systémy – a manželstvo, založené
        na majetku jednotlivca v kombinácii s niektorými z týchto iracionálnych náboženských systémov.<a href="#_edn6"
                                                                                                         id="_ednref6">[6]</a></em>
      </p>
      <p>Owenov čas v&nbsp; New Harmony bol krátky; v roku 1828 odišiel a opustil svoj socialistický experiment. Jeho
        myšlienky však zanechali trvalý vplyv.</p>
      <p>Ďalší vplyvný utopický socialista, francúzsky filozof Charles Fourier, poskytol Marxovi a jeho nasledovníkom
        veľa inšpirácie. Vplyv Fourierových diel možno vidieť počas revolúcie v roku 1848 a Parížskej komúny v roku
        1871. Jeho myšlienky sa neskôr rozšírili do Spojených štátov. Je zaujímavé, že Fourier je prvý filozof, o ktorom
        sa vie, že použil výraz „feminizmus“ (francúzsky féminisme).</p>
      <p>Vo Fourierovej ideálnej komunistickej spoločnosti (známej ako falangy) bola tradičná rodina opovrhovaná a
        pôžitky a orgie boli chválené ako plne oslobodzujúce ľudské vnútorné vášne. Takisto vyhlásil, že spravodlivá
        spoločnosť by sa mala starať o tých, ktorí sú sexuálne odmietnutí (ako napr. seniori alebo neatraktívni
        jedinci), aby sa zabezpečilo, že každý má „právo“ na sexuálne uspokojenie. Veril, že akákoľvek forma sexuálneho
        uspokojenia, vrátane sadomasochizmu a dokonca aj incestu a beštiality by mala byť povolená, pokiaľ tam je
        vzájomný súhlas.</p>
      <p>V devätnástom storočí v Spojených štátoch podnietil vplyv Owena a Fouriera vznik desiatok komunistických
        utopických komunít – väčšina z nich však mala krátke trvanie a skončila neúspechom. Najdlhšie trvajúcou z nich
        bola komunita Oneida, ktorá bola založená na základe Fourierovej teórie a trvala tridsaťtri rokov. Komunita sa
        vyhýbala tradičnému monogamnému manželstvu a obhajovala polygamiu, skupinový sex a selektívne plodenie. Nakoniec
        jej zakladateľ John Humphrey Noyes utiekol do Kanady, aby sa vyhol súdnemu konaniu. Hoci bola komunita nútená
        vzdať sa zdieľania manželiek, Noyes neskôr napísal niekoľko kníh, z ktorých jedna, „biblický komunizmus“, bola
        základom novej ideológie.</p>
      <p>Teoretické základy komunizmu idú ruka v ruke s promiskuitou. Komunizmus od samého začiatku povzbudzoval ľudí,
        aby opustili božské učenie a odmietli tradíciu, zvrhli morálne obmedzenia a oddávali sa základným pudom v záujme
        revolúcie a oslobodenia. Použitím komunistickej logiky možno sociálne problémy, ktoré boli pôvodne spôsobené
        degeneráciou ľudskej morálky, pripísať súkromnému vlastníctvu. Komunizmus vedie ľudí k presvedčeniu, že ak sa
        súkromné vlastníctvo stane verejným, ľudia oň nebudú bojovať. Aj napriek tomu, že je všetok majetok zdieľaný,
        môžu&nbsp; tam byť aj naďalej konflikty o&nbsp;partnerov niekoho iného. Preto ohľadom sexuálnej túžby utopickí
        socialisti otvorene obhajujú promiskuitu a „slobodnú lásku“.</p>
      <p>Tieto komunistické „raje“ buď priamo napádali tradičnú rodinu alebo obhajovali systém spoločných manželiek, čo
        viedlo miestne komunity, cirkvi a vlády k tomu, že ich považovali za hrozbu pre tradičnú morálku a etiku a
        prijali opatrenia na ich potlačenie.</p>
      <p>Marx a Engels sa zo zlyhania utopických komunít poučili: Ešte nebol vhodný čas otvorene obhajovať takzvanú
        „komunitu žien“ spomenutú v Komunistickom manifeste. Hoci sa ich cieľ odstrániť rodinu nezmenil, zaujali
        skrytejší prístup: útočiť na manželstvo ako na nástroj útlaku.</p>
      <p>Po Marxovej smrti vydal Engels knihu <em>Pôvod rodiny, súkromného vlastníctva a štátu</em><em>, vo svetle
        výskumov Lewisa H. Morgana</em>, aby dokončil Marxovu teóriu o rodine a ďalej objasnil marxistický pohľad na
        manželstvo: „[Vznik monogamie] je založený na nadradenosti muža a výslovným účelom je produkovať deti s
        nespochybniteľným otcovstvom. Takéto otcovstvo je potrebné, aby sa deti neskôr mohli stať prirodzenými dedičmi
        majetku svojho otca. V tom sa líši od manželstva skupinového, a to ďaleko silnejšou manželskou väzbou, ktorá sa
        už nedá zrušiť na želanie jedného z partnerov. Spravidla je to len muž, kto ju môže zrušiť, a tak sa zbaviť
        svojej manželky.“<a href="#_edn7" id="_ednref7">[7]</a></p>
      <p>Engels tvrdil, že monogamia je založená na súkromnom vlastníctve a že akonáhle bude všetok majetok zdieľaný,
        objaví sa úplne nový model manželstva založený výlučne na „sexuálnej láske“. Chvastal sa, že v komunistickej
        spoločnosti by sa súkromný majetok stal štátnym, že domáce práce sa budú profesionalizovať a nebude preto
        potrebné starať sa o deti, pretože sa o nich postará štát, ktorý ich vychová a&nbsp;vzdelá. Napísal: „Toto
        odstráni úzkosť ohľadom ‚následkov‘, čo je dnes ten najpodstatnejší sociálny – morálny i ekonomický – činiteľ,
        ktorý bráni dievčaťu v tom, aby sa úplne oddala mužovi, ktorého miluje. Nebude toto stačiť, aby nastal postupný
        nárast neviazaného sexuálneho styku a tým aj tolerantnejší názor verejnosti na cudnosť dievčaťa a hanbu ženy?“<a
          href="#_edn8" id="_ednref8">[8]</a></p>
      <p>Sociálna ideológia Marxa a Engelsa, podobne ako ich ekonomické teórie, zlyhávajú pri praktickej implementácii.
        City sú nestále; človek má rád niekoho dnes a zajtra&nbsp; niekoho iného. Bez tradičných noriem dvorenia a
        manželstva je nevyhnutným dôsledkom sexuálna promiskuita a rozpad spoločenského poriadku. Tak ako aj pri vyššie
        zmienených utopických komunitách, počiatočné pokusy sovietskeho a čínskeho komunistického režimu uplatňovať
        marxistickú doktrínu v rodinnej politike skončili úplným zlyhaním a&nbsp;boli rýchlo opustené.</p>
      <p>Vzťahy medzi manželom a manželkou nie sú vždy prechádzka ružovou záhradou. Sľub „kým nás smrť nerozdelí“ počas
        tradičnej svadby je sľubom Bohu. To tiež predstavuje myšlienku, že obe strany sú pripravené čeliť a prekonávať
        ťažkosti spoločne. To, čo udržuje manželstvo, nie sú iba emócie alebo city, ale tiež zmysel pre zodpovednosť.
        Správať sa k svojej polovičke, k deťom a k rodine s náležitou starostlivosťou, pretvára manžela a manželku na
        vyspelého jedinca so zmyslom pre morálnu a spoločenskú zodpovednosť.</p>
      <p>To, čo Marx s Engelsom presadzovali, hoci zabalené do fráz, ako „sloboda“, „oslobodenie“ a „láska“, nebolo v
        skutočnosti ničím iným než opustením osobnej morálnej zodpovednosti a odovzdaním sa vlastnej túžbe.</p>
      <p>Počas Fourierovej a Marxovej éry bola väčšina ľudí stále ešte nábožensky veriaca, a preto sa mali na pozore
        pred zjavnými pokusmi o podporu sexuálnej nemravnosti. Marx by si len ťažko dokázal predstaviť „zdôvodnenia“ s
        ktorými ľudia prišli počas dvadsiateho&nbsp; a dvadsiateho prvého storočia, aby prijali sexuálny chaos
        marxistickej školy a presadili ničenie rodiny a manželstva.</p>
      <h2>3. Prvé pokusy o sexuálne oslobodenie počas komunizmu</h2>
      <p>Autoritárske socialistické režimy sa často spájajú s prísnym sociálnym konzervativizmom, vrátane rodových úloh
        a zákonov o manželstve, ktoré sa zdajú byť mimo kontaktu so západným liberálnym progresivizmom. Takéto politiky
        však nie sú túžbou zachovať tradičnú kultúru alebo morálku, ale existujú iba na základe túžby komunistického
        režimu zmeniť lásku a rodinu na nástroje štátnej moci. Na začiatku komunistickej vlády v krajinách ako Rusko a
        Čína sa vedúci predstavitelia strany pokúsili implementovať celý marxistický program naraz, vrátane
        katastrofických experimentov so sexuálnym oslobodením.</p>
      <p>Ako už bolo uvedené skôr, sexuálny chaos je vrodenou črtou komunistickej ideológie. Verí sa, že Marx znásilnil
        svoju slúžku; nechal Engelsa vychovávať dieťa, ktoré z toho vzišlo. Engels mal pomer s dvoma sestrami. Lenin mal
        mimomanželské aféry a dostal syfilis od prostitútok. Je známe, že Stalin zneužíval manželky iných mužov.</p>
      <p>Potom, čo boľševici prevzali moc a založili Sovietsky zväz, zaviedli prax zdieľania manželiek. Sovietsky zväz v
        tom čase možno považovať za priekopníka sexuálneho oslobodenia. V roku 1990, rok pred pádom Sovietskeho zväzu,
        štátny ruský časopis <em>Rodina</em> publikoval článok, v ktorom načrtol fenomén zdieľania manželiek počas
        začiatku sovietskej vlády. Článok opísal súkromné životy sovietskych vodcov Leona Trockého, Nikolai Bukharina,
        Alexandra Kollontaia a ďalších, konštatujúc, že pri sexuálnych aktivitách sa „správali ako psy“. Už v roku 1904
        Lenin napísal: „Žiadostivosť môže oslobodiť energiu ducha – nie kvôli pseudo-rodinným hodnotám, ale kvôli
        víťazstvu socializmu treba s touto krvnou zrazeninou skoncovať.“<a href="#_edn9" id="_ednref9">[9]</a></p>
      <p>Na zasadaní ruskej sociálno-demokratickej strany práce Trockij navrhol, že hneď ako sa boľševici zmocnia moci,
        vypracujú nový koncept princípov sexuálnych vzťahov. Komunistická teória vyžaduje zničenie rodiny a prechod do
        obdobia neviazaného uspokojenia sexuálnych túžob. Trockij tiež povedal, že zodpovednosť za vzdelávanie detí by
        mala spočívať výlučne na štáte.</p>
      <p>V liste Leninovi z roku 1911 Trockij napísal: „Sexuálny útlak je bezpochyby hlavným prostriedkom, ako zotročiť
        človeka. Kým taký útlak existuje, nemožno hovoriť o ozajstnej slobode. Rodina, ako buržoázna inštitúcia, je
        úplne prežitá vec. Je nutné o tom viac hovoriť s robotníkmi“.</p>
      <p>Lenin odpovedal: „A nielen rodina. Musíme opustiť všetky zákazy ohľadom sexuality. ... Máme sa ešte od
        sufražetiek čo učiť:&nbsp; mal by sa zrušiť aj zákaz lásky medzi príslušníkmi rovnakého pohlavia“<a
          href="#_edn10" id="_ednref10">[10]</a></p>
      <h3>a) Sovietska teória „pohára vody“</h3>
      <p>Keď sa boľševici dostali k moci, Lenin vydal rad nariadení, ktoré prakticky skoncovali s manželstvom a
        dekriminalizovali homosexualitu. V tejto dobe sa objavil slogan „Preč s hanbou!“. Jednalo sa o súčasť
        boľševického plánu vytvoriť „nového človeka“ socialistickej ideológie. Objavili sa aj prípady, keď ľudia behali
        nahí po uliciach a hystericky vykrikovali slogany ako: „Hanba je buržoáznou minulosťou sovietskeho ľudu.“<a
          href="#_edn11" id="_ednref11">[11]</a></p>
      <p>Na začiatku 20. rokov minulého storočia popularizovala bývala ľudová komisárka pre sociálnu starostlivosť
        Alexandra Kollontaiová teóriu „pohára vody“ týkajúcu sa sexuality. Kollontaiová bola tiež revolucionárkou, ktorá
        si z tradičnej rodiny prebojovala cestu do boľševických radov v snahe nájsť „oslobodenie pre ženy“. „Pohár vody“
        je narážka na sexuálne pôžitkárstvo; teória zastávala názor, že v komunistickej spoločnosti by uspokojenie
        sexuálnej túžby malo byť také normálne a ľahké ako vypitie pohára vody. Tento koncept sa široko&nbsp; rozšíril
        medzi robotníkmi v továrňach a najmä medzi&nbsp; dospievajúcimi študentami.</p>
      <p>Známa komunistka Smidovičová zhrnula súdobú morálku mládeže v komunistických&nbsp; novinách Pravda v marci 1925
        takto:</p>
      <p><em>Každý člen, hoci nevýznamný, Komunistického zväzu mládeže a každý študent Rabfaku (výcviková škola
        komunistickej strany), má právo uspokojiť svoje sexuálne túžby. Tento koncept sa stal axiómou a vyhýbanie sa
        pohlavnému styku je považované za buržoázny názor. Ak muž prahne po mladej dievčine, či už je to študentka,
        robotníčka alebo i školáčka, potom to dievča musí jeho žiadostivosti vyhovieť, inak bude považovaná za buržoáznu
        dcéru nehodnú označenia pravej komunistky.<a href="#_edn12" id="_ednref12">[12]</a></em></p>
      <p>Z rozvodu sa tiež stalo niečo bežného a rozšíreného. „Miera rozvodovosti dosiahla úroveň doteraz v ľudskej
        histórii nevídanú. Počas krátkej doby to vyzeralo, že sa rozviedol každý Moskovčan,“ poznamenal profesor Paul
        Kengor vo svojej knihe z roku 2015 <em>Pád: Od komunistov k progresívcom. Ako ľavica sabotovala rodinu a
          manželstvo</em> (Takedown: From Communists to Progressives, How the Left Has Sabotaged Family and Marriage).<a
          href="#_edn13" id="_ednref13">[13]</a> Americký časopis <em>The Atlantic</em> publikoval v roku 1926 článok o
        šokujúcej situácii v ZSSR s názvom „Ruské snahy o zrušenie manželstva“.</p>
      <p>V tomto období sexuálneho oslobodenia sa tiež objavil fenomén „švédskych rodín“, ktorý však nemá so Švédmi nič
        spoločné. Označuje veľkú skupinu mužov a žien, ktorí spolu žijú a oddávajú sa neviazanému sexu. To otvorilo
        dvere promiskuite, znásilňovaniu, deštrukcii rodiny, sexuálne prenosným chorobám, a ďalším príznakom morálneho
        úpadku.<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>S expanziou socialistických komunít sa v Sovietskom zväze rozšírili aj tieto „švédske rodiny“. Hovorilo sa tomu
        „znárodnenie“ alebo „socializácia“ žien. Smutným príkladom sú socialistické ženy v Jekaterinburgu v roku 1918:
        Keď sa mesta zmocnili boľševici, vydali nariadenie, že musia byť „socializované“ mladé ženy vo veku 16 – 25
        rokov. Vojakom červenej armády a štátnym funkcionárom tak bolo doručené neznáme množstvo žien, aby boli
        „socializované“.<a href="#_edn15" id="_ednref15">[15]</a></p>
      <p>Počas rozhovoru s feministickou aktivistkou Clarou Zetkinovou v roku 1921 odsúdil Lenin teóriu „pohára vody“ a
        nazval ju anti-marxistickou a anti-sociálnou.<a href="#_edn16" id="_ednref16">[16]</a> Dôvodom bolo to, že
        sexuálne oslobodenie prinieslo nežiaduci vedľajší efekt: príliv nechcených detí, z ktorých mnohé zostali
        opustené. Koncom 20. rokov boľševici sprísnili svoju politiku v oblasti sexu.</p>
      <p>Po Leninovej smrti komunistická strana Sovietskeho zväzu zakročila proti sexuálnej liberalizácii, ktorú predtým
        podporovala a v určitých obdobiach vynucovala nariadeniami. Mnoho komunistov, ktorí presadzovali voľnú lásku
        a&nbsp;homosexualitu, spolu s&nbsp;veľkým počtom ďalších ľudí, ktorí verili v revolučný program, skončilo v
        Stalinových gulagoch. Sovietske ženy boli povzbudzované, aby sa ujali svojej tradičnej úlohy matiek, produkovali
        viac detí a vychovávali ich tak, aby slúžili komunistickej strane.</p>
      <h3>b) Sexuálne oslobodenie v čínskych „sovietskych provinciách“</h3>
      <p>Počas prvých rokov existencie ČKS bola situácia v Číne podobná ako v Sovietskom zväze. Komunistické strany sú
        ako rôzne druhy jedovatého ovocia z toho istého stromu. Jeden z raných komunistických vodcov, Čchen Tu-Siou, bol
        známy svojím zhýralým životom. Podľa pamätí trockistických kádrov Čeng Cchao-lina a Čchen Pi-lana mali komunisti
        ako Čchü Čchiou-paj, Cchaj Che-sen, Čang Tchaj-lej, Siang Ťing-jü, Pcheng Šu-č‘&nbsp; trochu „chaotickú“
        sexuálnu históriu a ich postoj k pohlavnému styku sa podobal na koncept „pohára vody“ u prvých sovietskych
        revolucionárov.</p>
      <p>„Sexuálne oslobodenie“ prijali&nbsp; nielen intelektuálni vodcovia strany, ale aj bežní ľudia žijúci v raných
        „sovietskych provinciách“ ČKS, čo boli revolučné enklávy založené pred zvrhnutím Nacionalistickej strany v
        provinciách Chu-pej, Che-nan a An-chuej. Z dôvodu podpory rovnosti žien a absolútnej slobody uzatvárať
        manželstvá a rozvody sa revolučná práca často prerušila s cieľom uspokojenia sexuálnej túžby.</p>
      <p>Mladí ľudia v sovietskych provinciách sa niekedy oddávali láske v mene „spájania sa s masami.“ U mladých žien
        nebolo neobvyklé mať šesť alebo sedem sexuálnych partnerov. Podľa <em>Zbierky historických revolučných
          dokumentov v sovietskych provinciách Chu-pej</em>, <em>Che-nan</em> a <em>An-chuej, </em>medzi miestnymi
        straníckymi šéfmi v oblastiach Chung‘an, Chuang-ma, Chuang-čchi, Kuang-šan a inde „okolo tri štvrtiny z nich
        udržiavalo sexuálne vzťahy s desiatkami či stovkami žien“.</p>
      <p>Koncom jari roku 1931, keď sa zakladajúci člen ČKS Čang Kuo-tchao ujal vedenia sovietskych provincií,
        poznamenal, že syfilis je taký rozšírený, že musel nahlásiť ústrediu strany, aby mu poslalo lekárov, ktorí sa na
        toto ochorenie špecializujú. O mnoho rokov neskôr si vo svojich pamätiach stále živo vybavoval príbehy žien,
        ktoré boli&nbsp; sexuálne obťažované, a to vrátane niektorých mileniek vysokých generálov.<a href="#_edn17"
                                                                                                     id="_ednref17">[17]</a>
      </p>
      <p>V 30. rokoch 20. storočia už však sexuálna sloboda začala režim ohrozovať. Vznikol tak ten istý problém
        spoločenského rozvratu ako v sovietskom Rusku. Odvedenci Červenej armády sa začali báť, že im ženy po tom, čo sa
        pridajú k revolúcii, budú neverné alebo sa s nimi rozvedú. To malo vplyv na bojaschopnosť vojska. Okrem toho
        rýchle rozšírenie promiskuity vyvolalo silný odpor verejnosti proti myšlienke „zdieľania manželiek“ a podobným
        názorom. Sovietske enklávy preto začali zavádzať politiku ochrany manželstiev vojakov a obmedzovať počet
        rozvodov.</p>
      <h2>4. Ako komunizmus ničí rodiny na Západe</h2>
      <p>Ideologické trendy komunizmu vznikli v 19. storočí. Po viac ako storočí transformácie a vývoja na Západe sa v
        60. rokoch 20. storočia dostali do popredia v Spojených štátoch.</p>
      <p>Počas toho desaťročia sa objavili deviantné spoločenské a kultúrne hnutia, ovplyvnené a podporované
        neomarxizmom a rôznymi inými radikálnymi ideológiami. Napríklad kontrakultúra hippies, radikálna Nová ľavica
        (New Left), feministické hnutie a hnutie za sexuálnu revolúciu. Tieto búrlivé sociálne hnutia boli súčasťou
        prudkého útoku&nbsp; proti politickému systému USA, tradičnému hodnotovému rebríčku a zažitému spoločenskému
        usporiadaniu. Tieto hnutia sa rýchlo rozšírili do Európy, kde rapídne zmenili spôsob, akým ľudia z hlavného
        prúdu spoločnosti nahliadali na spoločnosť, rodinu, sexuálne vzťahy a kultúrne hodnoty. To viedlo k oslabeniu
        tradičných západných rodinných hodnôt a k úpadku inštitúcie rodiny a jej ústredného postavenia v spoločenskom
        živote. To ďalej viedlo k spoločenskému chaosu a radu problémov vrátane šírenia pornografie, nárastu konzumácie
        drog, pádu sexuálnej morálky, nárastu kriminality mladistvých a rozrastaniu sa skupín občanov závislých na
        príspevkoch od štátu.</p>
      <h3>a) Propagácia sexuálneho oslobodenia</h3>
      <p>Hnutie za sexuálne oslobodenie (tiež známe ako sexuálna revolúcia) vzniklo v Spojených štátoch v 60. rokoch 20.
        storočia. Hnutie voľnej lásky, ktoré narúša tradičnú sexuálnu morálku, vydláždilo cestu postupnej erózii a
        rozpadu tradičných rodinných hodnôt. Koncept „voľnej lásky“ predpokladá, že sexuálna aktivita v akejkoľvek forme
        by nemala byť nijako spoločensky regulovaná. Podľa tohto pohľadu by manželstvo, potraty a cudzoložstvo nemali
        byť obmedzené vládou ani zákonom a ani podliehať spoločenským sankciám.</p>
      <p>Nasledovníci Fouriera a Johna Humphreya Neyesa boli prvými, ktorí použili výraz „voľná láska“. V súčasnosti
        takmer všetci hlavní propagátori myšlienok voľnej lásky sú socialisti alebo ľudia hlboko ovplyvnení
        socialistickým myslením. Napríklad socialistický filozof Edward Carpenter bol medzi tými, ktorí propagovali
        hnutie za voľnú lásku vo Veľkej Británii a bol jedným z prvých aktivistov za práva homosexuálov. Vo Francúzsku
        bol hlavným poslom hnutia voľnej lásky Émile Armand, spočiatku anarchistický komunista, ktorý neskôr staval na
        utopistickom komunizme Fouriera. Armand založil francúzsky individualistický anarchizmus (ktorý patrí do širšej
        kategórie socializmu) a obhajoval promiskuitu, homosexualitu a bisexualitu. V Austrálii bol priekopníkom voľnej
        lásky odborár a&nbsp; anarchista John „Chummy“ Fleming (anarchizmus je ďalšou odnožou socializmu).</p>
      <p>Hnutie voľnej lásky v Amerike prinieslo svoje ovocie, keď bol v&nbsp; roku 1953 založený erotický časopis
        Playboy. Na tlač použili kriedový papier, aby vytvorili dojem, že ide o exkluzívny časopis a nie o plátok
        pochybnej povesti. Časopis použil nákladnú farebnú tlač, výsledkom čoho bolo, že pornografický obsah – zvyčajne
        považovaný za niečo podradného a vulgárneho – sa rýchlo dostal do hlavného prúdu spoločnosti a Playboy sa stal
        „kvalitným“ časopisom na voľný čas.</p>
      <p>S tým, ako v polovici 20. storočia začala postupne rásť popularita hippies a voľná láska sa dočkala širokej
        akceptácie, vstúpila na scénu sexuálna revolúcia, tiež známa ako sexuálne oslobodenie. Pojem „sexuálna
        revolúcia“ bol vytvorený Wilhelmom Reichom, rakúskym zakladateľom komunistickej psychoanalýzy. Reich kombinoval
        marxizmus s freudovskou psychoanalýzou a veril, že marxizmus oslobodí ľudí od „ekonomického útlaku“, zatiaľ čo
        Freudovská psychoanalýza oslobodí ľudí od „sexuálneho útlaku“.</p>
      <p>Ďalším zakladateľom teórie sexuálneho oslobodenia bol Herbert Marcus z Frankfurtskej školy. Počas hnutia
        kontrakultúry 60. rokov, ktoré prebehlo na Západe, sa rozšíril Marcusov slogan „Milujte sa, nebojujte“ (Make
        Love, not War), ktorý do ľudských sŕdc hlboko zasial názory o sexuálnom oslobodení.</p>
      <p>Myšlienky sexuálneho oslobodenia sa rýchlo rozšírili po celom Západe. Prispeli k tomu aj tzv. Kinseyho správy.
        Dve knihy – <em>Sexuálne správanie muža</em> a <em>Sexuálne správanie ženy</em>, ako aj široká dostupnosť
        antikoncepcie vo forme tabletiek. Stojí za zmienku, že súčasní vedci objavili skreslené štatistické údaje v
        práci Alfreda Kinseyho, ako aj preháňanie, prílišné zjednodušovanie a ďalšie omyly, ktorých sa dopustil v mene
        svojich politických a ideologických pohnútok. Kinsey si dal za cieľ ukázať, že mimomanželský sex, homosexualita,
        sexuálna túžba detí, už od dojčiat, je niečo bežné, aby tak nasmeroval spoločnosť k prijatiu týchto javov ako
        niečo normálne. Je nutné dodať, že sa mu to do značnej miery podarilo. Vo svojich výskumoch a sexuálnych
        experimentoch s deťmi a dojčatami pracoval s pedofilmi.<a href="#_edn18" id="_ednref18">[18]</a></p>
      <p>Zrazu sa „sexuálne oslobodenie“ stalo módnym trendom. Striedanie partnerov sa u mladých ľudí začalo považovať
        za úplne bežné. Dospievajúci, ktorí sa priznali, že ešte nemali sex, boli svojimi rovesníkmi zosmiešňovaní.
        Údaje ukazujú, že u tých, ktorí dosiahli medzi rokmi 1954 až 1963 (generácia 60. rokov) pätnásť rokov, malo 82 %
        predmanželský sex pred dosiahnutím tridsiatich rokov.<a href="#_edn19" id="_ednref19">[19]</a> V roku
        2010&nbsp; bolo iba 5 % amerických neviest pannami, zatiaľ čo 18 % už malo desať a viac sexuálnych partnerov.<a
          href="#_edn20" id="_ednref20">[20]</a> Hlavný prúd kultúry je presýtený sexom, či už v literatúre, filme,
        reklamách a v televízii.</p>
      <h3>b) Propagácia feminizmu a odmietanie tradičnej rodiny</h3>
      <h4>Komunistická ideológia za feministickým hnutím</h4>
      <p>Feministické hnutie je ďalším nástrojom, ktorý komunizmus použil na zničenie rodiny. Feministické hnutie (známe
        tiež ako prvá vlna feminizmu) začalo v Európe v 18. storočí a obhajovalo, aby sa so ženami vo vzdelávaní,
        zamestnaní a v politike zaobchádzalo rovnako ako s mužmi. Centrum feministického hnutia sa v polovici 19.
        storočia presunulo z Európy do Spojených štátov.</p>
      <p>Keď sa začala prvá vlna feminizmu, predstava tradičnej rodiny mala v spoločnosti stále silné základy a
        feministické hnutie sa neodvážilo ísť priamo proti tradičnej rodine. Vtedajšie vplyvné feministky ako Mary
        Wollstonecraftová v 18. storočí v Anglicku, Margaret Fullerová v 19. storočí v Amerike a John Stuart Mill v 19.
        storočí v Anglicku, všetci obhajovali názor, že ženy by vo všeobecnosti po svadbe mali uprednostniť rodinu, že
        potenciál žien by sa mal rozvíjať v rámci rodiny a že ženy by si mali zvýšiť svoju hodnotu (napríklad
        prostredníctvom vzdelávania), aby rodina viac prosperovala. Tieto rané feministky verili, že ženy s určitým
        talentom by nemali byť obmedzované spoločenskými normami a mali by mať možnosť rozvíjať svoj talent i v
        prostrediach, kde dominujú väčšinou muži.</p>
      <p>V mnohých krajinách prvá vlna feminizmu utíchla po vyhlásení volebného práva žien, keďže cieľ rovnosti mužov a
        žien pred zákonom bol dosiahnutý. V nasledujúcich rokoch bolo feministické hnutie v dôsledku veľkej hospodárskej
        krízy a druhej svetovej vojny prakticky pozastavené.</p>
      <p>Komunizmus však zasial semená deštrukcie pre tradičné manželstvo a sexuálnu výchovu už oveľa skôr. Základy
        súčasných radikálnych feministických hnutí položili v 19. storočí raní utopickí socialisti. Fourier, nazývaný
        „otec feminizmu“ tvrdil, že manželstvo premenilo ženy na súkromný majetok. Owen preklínal manželstvo ako zlo.
        Myšlienky týchto utopických socialistov zdedili a rozvíjali neskoršie feministky vrátane napríklad Frances
        Wrightovej, ktorá prevzala Fourierove myšlienky a obhajovala sexuálnu slobodu žien v devätnástom storočí.</p>
      <p>Britská feministická aktivistka Anna Wheelerová prijala Owenove myšlienky a prudko odsúdila manželstvo,
        s&nbsp;argumentom, že údajne premieňa ženy na otrokyne. Socialistickí feministickí aktivisti boli tiež dôležitou
        súčasťou feministického hnutia v 19. storočí. V tom čase patrili medzi najvplyvnejšie feministické publikácie vo
        Francúzsku <em>Hlas žien (La Voix des Femmes)</em>, <em>Slobodná žena (La Femme Libre)</em> neskôr premenovaná
        na <em>Tribúna žien (La Tribune des Femmes)</em> a&nbsp;<em>Politika žien (La Politique des Femmes)</em>.
        Zakladateľmi týchto publikácií boli buď stúpenci Fouriera alebo Henri de Saint-Simona, zástancu moderného
        priemyselného socializmu.</p>
      <p>Keď bola prvá vlna hnutí za práva žien v plnom prúde, komunisti sa dohodli na predstavení rôznych radikálnych
        myšlienok, aby napadli tradičné koncepty rodiny a manželstva, čím vydláždili cestu radikálnejšiemu
        feministickému hnutiu, ktoré nasledovalo.</p>
      <p>Druhá vlna feministických hnutí sa začala v Spojených štátoch koncom 60. rokov 20. storočia, potom sa rozšírila
        do západnej a severnej Európy a rýchlo expandovala do celého západného sveta. Americká spoločnosť koncom 60.
        rokov prešla obdobím nepokojov, hnutím za občianske práva, hnutím proti vojne vo Vietname a rôznymi radikálnymi
        spoločenskými trendmi. Za takýchto pohnutých okolností sa objavila a&nbsp;spopularizovala radikálna vlna
        feminizmu.</p>
      <p>Základným kameňom tejto vlny feministických hnutí bola kniha <em>Ženská mystika </em>od Betty Friedanovej,
        vydaná v roku 1963, a Národná organizácia pre ženy (NOW), ktorú spoluzaložila. Friedanová tvrdo kritizovala
        tradičné rodinné úlohy žien a tvrdila, že klasický obraz spokojnej a radostnej ženy v domácnosti je mýtus
        patriarchálnej spoločnosti. Tvrdila, že prímestský dom strednej triedy je pre americké ženy „pohodlným
        koncentračným táborom“ a že moderné, vzdelané ženy by mali odmietnuť pocit úspechu dosiahnutý podporou od
        svojich manželov a výchovou svojich detí, a namiesto toho si uvedomiť svoju hodnotu mimo rodiny.<a
          href="#_edn21" id="_ednref21">[21]</a></p>
      <p>O niekoľko rokov neskôr dominovali ešte radikálnejšie feministky, ktoré zdedili a rozvíjali myšlienky
        Friedanovej. Povedali, že patriarchát utláčal ženy už od staroveku a pripisovali rodine hlavnú príčinu útlaku
        žien. V reakcii na to obhajovali úplnú transformáciu sociálneho systému a tradičnej kultúry a bojovali vo
        všetkých oblastiach – hospodárstve, vzdelávaní, kultúre i v&nbsp; rodine – aby&nbsp; dosiahli rovnosť žien.<a
          href="#_edn22" id="_ednref22">[22]</a></p>
      <p>Klasifikácia členov spoločnosti na „utláčateľov“ a „utláčaných“ s cieľom obhajovať boj, oslobodenie
        a&nbsp;rovnosť, je presne to, čo robí komunizmus. Klasický marxizmus klasifikuje skupiny podľa ich ekonomických
        statusov, zatiaľ čo neofeministické hnutia rozdeľujú ľudí na základe pohlavia.</p>
      <p>Friedanová nebola, ako píše vo svojej knihe, jednoduchou ženou v domácnosti zo strednej triedy, ktorá je
        unudená domácimi prácami. Daniel Horowitz, profesor na Smith College, napísal v roku 1998 životopis o
        Friedanovej s názvom Betty Friedanová a ako vznikla <em>„Ženská mystika“. </em>Jeho výskum odhalil, že
        Friedanová bola pod svojím dievčenským menom Betty Goldsteinová radikálnou socialistickou aktivistkou od svojich
        študentských čias na univerzite až do 50. rokov 20. storočia. Na Kalifornskej univerzite v Berkeley bola členkou
        komunistického zväzu mládeže a dokonca dvakrát požiadala o vstup do Komunistickej strany USA (KSUSA).
        Friedanovej oficiálna životopiskyňa, Judith Hennesseeová, tiež naznačuje, že Friedanová bola marxistka. V
        rôznych obdobiach bola profesionálnou novinárkou – presnejšie propagandistkou – pre niekoľko radikálnych
        odborových zväzov pod sférou vplyvu KSUSA. <a href="#_edn23" id="_ednref23">[23]</a> <a href="#_edn24"
                                                                                                id="_ednref24">[24]</a>
      </p>
      <p>Americká akademička Kate Weigandová vo svojej knihe <em>Červený feminizmus: Americký komunizmus a presadzovanie
        oslobodenia žien (Red Feminism: American Communism and the Making of Women’s Liberation)</em> poukazuje na fakt,
        že feminizmus v skutočnosti v Spojených štátoch od začiatku 20. storočia do 60. rokov 20. storočia neutíchol,
        ale že počas tohto obdobia veľká skupina feministických autoriek s komunistickým pozadím vydláždila cestu pre
        následnú druhú vlnu feministického hnutia. Patrí medzi nich Susan Anthonyová a Eleanor
        Flexnerová.&nbsp; Anthonyová už v roku 1946 aplikovala marxistickú analytickú metódu, aby vytvorila analógiu
        medzi útlakom černochov belochmi a útlakom žien zo strany mužov. Kvôli McCarthizmu vtedajšieho obdobia si tieto
        autorky nedovolili odhaliť svoje komunistické pozadie. <a href="#_edn25" id="_ednref25">[25]</a></p>
      <p>V Európe viedlo ikonické dielo francúzskej spisovateľky Simone de Beauvoirovej, <em>Druhé pohlavie (The Second
        Sex)</em>, k náporu druhej vlny feminizmu. De Beauvoirová bola socialistka, ktorá v roku 1941 spoločne s
        komunistickým filozofom Jean-Paul Sartrom a ďalšími spisovateľmi založila francúzsku undergroundovú
        socialistickú organizáciu <em>Socializmus a&nbsp;sloboda (Socialisme et Liberté)</em>. Po náraste jej popularity
        pri propagovaní feminizmu v 60. rokoch 20. storočia de Beauvoirová&nbsp; vyhlásila, že na socializmus už neverí
        a že je iba feministkou.</p>
      <p>„Človek sa ženou nenarodí, ale sa ňou stáva“, vyhlásila de Beauvoirová a tvrdila, že hoci je pohlavie určené
        fyziologickými charakteristikami, ide o subjektívne vnímaný psychologický koncept, vytvorený pod vplyvom ľudskej
        spoločnosti. Podľa nej sú poslušnosť, poddajnosť, láska a materstvo založené na „mýte“, starostlivo zostavenom
        patriarchátom za účelom utláčania žien. De Beauvoirová zastávala názor, aby sa ženy vymanili z tradičných
        predstáv a uvedomili si svoje nespútané ja.</p>
      <p>Od tej doby sa v neustálom prúde objavujú rôzne feministické myšlienky, pričom všetky sa pozerajú na svet
        optikou žien, ktoré sú utláčané patriarchátom v rámci inštitúcie tradičnej rodiny – čo nakoniec robí z rodiny
        prekážku pri realizácii rovnoprávnosti žien.<a href="#_edn26" id="_ednref26">[26]</a></p>
      <p>Mnoho súčasných radikálnych feministiek zastáva názor, že ženy sú manželmi obmedzované vďaka manželstvu a
        dokonca inštitúciu manželstva nazývajú prostitúciou. Podobne ako prví komunistickí utopisti, ktorí hovorili o
        „zdieľaných manželkách“ alebo „komunite žien“, obhajujú „otvorené vzťahy“ a nespútanú sexuálnu aktivitu.</p>
      <p>&nbsp;</p>
      <h4>Výsledky feministického hnutia</h4>
      <p>Feminizmus v súčasnosti prevláda vo všetkých odvetviach spoločnosti. Jedným z hlavných tvrdení súčasného
        feminizmu je, že okrem fyziologických rozdielov v mužských a ženských reprodukčných orgánoch sú všetky ostatné
        fyzické a psychologické rozdiely medzi mužmi a ženami sociálnymi a kultúrnymi konštruktmi. Podľa tejto logiky by
        mali byť muži a ženy vo všetkých aspektoch života a spoločnosti úplne rovnocenní a všetky prejavy „nerovnosti“
        medzi mužmi a ženami sú výsledkom kultúry a spoločnosti, ktorá je utláčateľská a sexistická.</p>
      <p>Napríklad počet mužov pracujúcich vo vedúcich funkciách vo veľkých spoločnostiach, akademikov s vysokými
        funkciami na elitných univerzitách alebo vyšších vládnych úradníkov výrazne prevyšuje počet žien na podobných
        pozíciách. Mnohé feministky sa domnievajú, že je to primárne spôsobené sexizmom, pričom v skutočnosti možno
        spravodlivé porovnanie medzi pohlaviami urobiť iba pri posudzovaní faktorov ako sú schopnosti, pracovná doba,
        pracovná morálka, životné ciele a podobne. Úspech na vysokých pozíciách si často vyžaduje dlhodobú prácu s
        vysokým nasadením i počas nadčasov – obetovanie víkendov a večerov, účasť na náhlych mimoriadnych schôdzach,
        časté služobné cesty atď.</p>
      <p>Materstvo zvyčajne preruší kariéru ženy a ženy sa potom venujú svojim rodinám a deťom namiesto toho, aby sa
        úplne venovali svojej práci. Okrem toho ľudia schopní obsadiť vysoké pozície mávajú zvyčajne silnú a tvrdú
        povahu, zatiaľ čo ženy majú tendenciu byť jemnejšie. Feministky však ženskú jemnosť a uprednostňovanie rodiny a
        detí považujú za vlastnosti, ktoré na ženy uvalila sexistická spoločnosť. Podľa feministiek by tieto rozdiely
        mali kompenzovať verejne financované služby, ako verejná starostlivosť o deti a iné formy sociálnej
        starostlivosti.</p>
      <p>Akékoľvek vysvetlenie rozdielov medzi mužmi a ženami, ktoré je založené na prirodzených fyziologických a
        psychologických vlastnostiach, súčasný feminizmus netoleruje. Podľa tejto ideológie&nbsp; zo všetkého je
        potrebné viniť spoločenské návyky a tradičné hodnoty.</p>
      <p>Na akademickej konferencii v roku 2005 Lawrence Summers, vtedajší rektor Harvardskej univerzity, naznačil,
        prečo je vo vedeckých a matematických odboroch na špičkových vysokých školách menej žien ako mužov. Summers
        okrem zdôraznenia odpracovania viac než osemdesiatich hodín týždenne a nepredvídateľného pracovného harmonogramu
        vyžadovaného pre tieto pozície (čas, ktorý by väčšina žien vyhradila pre rodinu), tiež prezentoval názor, že
        muži a ženy sa môžu jednoducho líšiť v schopnostiach pokiaľ ide o pokročilú vedu a matematiku a že diskriminácia
        už nie je prekážkou.<a href="#_edn27" id="_ednref27">[27]</a></p>
      <p>Napriek tomu, že svoje vyhlásenia podložil relevantnými štúdiami, stal sa terčom protestov&nbsp; feministickej
        organizácie NOW. Skupina ho obvinila zo sexizmu a požadovala jeho odvolanie. Summers bol opakovane kritizovaný v
        médiách a musel sa za svoje výroky verejne ospravedlniť. Po tejto udalosti nechal vyčleniť 50 miliónov dolárov
        na zvýšenie diverzity na univerzite.<a href="#_edn28" id="_ednref28">[28]</a></p>
      <p>V roku 1980 časopis Science uverejnil štúdiu, v ktorej sa uvádza, že študenti a študentky stredných škôl
        vykazujú výrazné rozdiely pokiaľ ide o schopnosti matematického uvažovania, pričom chlapci prekonávali dievčatá.<a
          href="#_edn29" id="_ednref29">[29]</a>&nbsp;V nasledujúcej štúdii, ktorá porovnávala skóre matematických
        testov SAT u chlapcov a dievčat, vyšlo najavo, že u chlapcov je štyrikrát vyššia pravdepodobnosť, že dosiahnu
        skóre cez 600 bodov. Táto medzera sa ešte zväčšila pri 700 bodovej hranici, kde bol rozdiel v prospech chlapcov
        v testoch dokonca 13-násobný.<a href="#_edn30" id="_ednref30">[30]</a></p>
      <p>Rovnaký výskumný tím vykonal v roku 2000 ďalšiu štúdiu, z ktorej vyplynulo, že študenti i študentky, ktorí v
        testoch SAT vykazovali geniálne matematické skóre, zhodne získavali pokročilé tituly vo vedeckých a
        matematických odboroch a boli spokojní so svojimi úspechmi.<a href="#_edn31" id="_ednref31">[31]</a></p>
      <p>Niektoré správy uvádzajú, že to, ako sa zaobchádzalo so Summersom&nbsp; po jeho prejave v roku 2005,
        odzrkadľuje reedukačné politiky používané komunistickými režimami na potlačenie disidentov. Aj keď ešte neboli
        stanovené príčiny nerovnosti, rovnosť výsledkov bola vynútená podporou „diverzity“ – zvýšil sa počet
        inštruktoriek v matematických a prírodných vedách.</p>
      <p>Prepojenia medzi feminizmom a socializmom sú zrejmé. Alexis de Tocqueville v roku 1848 povedal: „Demokracia a
        socializmus nemajú nič spoločné, až na jedno slovo: rovnosť. Všimnite si však rozdiel: Zatiaľ čo demokracia sa
        usiluje o rovnosť slobody, socializmus sa usiluje o rovnosť v obmedzovaní a&nbsp;nútenej práci.“ <a
          href="#_edn32" id="_ednref32">[32]</a></p>
      <p>Hoci príčiny psychologických a intelektuálnych rozdielov medzi mužmi a ženami nemusia byť zrejmé, popieranie
        ich fyzických a reprodukčných rozdielov je proti zdravému rozumu. V tradičnom pohľade Východu i Západu sú muži
        zvyčajne vykresľovaní ako ochrancovia. Je normálne, že hasiči sú prevažne muži. Avšak feministky veria v
        absolútnu rovnosť mužov a žien a požadujú, aby ženy prevzali tradičné mužské povinnosti, pričom nedomysleli
        dôsledky.</p>
      <p>V roku 2015 hasičský zbor v New Yorku umožnil žene stať sa hasičkou bez toho, aby musela prejsť fyzickými
        skúškami, ktoré zahŕňajú splnenie rôznych úloh, pričom je zároveň potrebné niesť kyslíkovú fľašu a ďalšie
        vybavenie vážiace 23 kilogramov. Hasičské oddelenie ženu nakoniec zamestnalo aj preto, aby sa vyhlo súdnemu
        konaniu: feministické skupiny vytrvalo newyorských hasičov obviňovali a tvrdili, že za nízkou mierou žien
        vstupujúcich do hasičských zborov môžu byť príliš náročné fyzické normy. Ostatní hasiči, vrátane žien, ktoré
        prešli testom ohľadom toho vyjadrili znepokojenie s odôvodnením, že kolegovia, ktorí nemôžu tieto normy splniť,
        sa nevyhnutne stanú bremenom a nebezpečenstvom pre zvyšok tímu i verejnosť.<a href="#_edn33"
                                                                                      id="_ednref33">[33]</a></p>
      <p>V Austrálii zaviedli požiarne oddelenia rodové kvóty v roku 2017.&nbsp; Ku každému prijatému mužovi musí byť
        prijatá aj žena. Aby bola táto požiadavka splnená, boli pre ženy stanovené výrazne nižšie fyzické normy, a to
        napriek tomu, že nebezpečná a stresujúca práca&nbsp; je rovnaká pre obe pohlavia.<a href="#_edn34"
                                                                                            id="_ednref34">[34]</a></p>
      <p>Táto nelogická kampaň za rovnosť výsledkov išla ešte ďalej. Kvóty spôsobili spory medzi hasičmi a hasičkami,
        ktoré tvrdili, že ich mužskí kolegovia obviňujú z nekvalifikovanosti a nekompetentnosti. Feministické skupiny sa
        toho chytili ako „šikanovania“ a „psychologického nátlaku“. Situácia vytvorila ďalšiu možnosť pre feministky,
        aby bojovali za svoju zdanlivú túžbu po rovnosti.</p>
      <p>Táto absurdita je však zámerným krokom v plánoch ducha komunizmu: tým, že feminizmus napáda patriarchát – teda
        tradičnú spoločnosť – podkopáva tradičnú rodinu rovnakým spôsobom, akým triedny boj podkopáva kapitalistický
        systém.</p>
      <p>V tradičnej kultúre je samozrejmosťou, že muži by mali byť mužní a ženy by mali byť jemné. Muži majú
        zodpovednosť za svoju rodinu a komunitu tým, že chránia svoje ženy a deti – čo je práve tá silná patriarchálna
        štruktúra, ktorú feminizmus spochybňuje s odôvodnením, že mužom poskytuje nespravodlivé výhody, zatiaľ čo ženy
        utláča. Feminizmus nemá pre tradičného ducha rytierstva alebo džentlmenského správania miesto. Vo feministickom
        svete by muži na palube potápajúceho sa Titanicu neobetovali svoje miesta v záchranných člnoch pasažierkam, aby
        mali väčšiu šancu sa zachrániť.</p>
      <p>Feministické ťaženie proti patriarchátu malo takisto výrazný vplyv na vzdelávanie. Súd v Pensylvánii, ktorý v
        roku 1975 rozhodol v súdnom spore proti Medziuniverzitnej atletickej federácii, nariadil školám, aby študentkám
        umožnili „cvičiť a súťažiť s chlapcami“&nbsp; v tímových športoch a iných fyzických aktivitách, vrátane
        zápasenia a amerického futbalu. Dievčatá už nemohli byť vylúčené z mužského mužstva len na základe pohlavia.<a
          href="#_edn35" id="_ednref35">[35]</a></p>
      <p>Americká akademička Christina Hoff Sommersová vo svojej knihe z roku 2013 <em>Vojna proti chlapcom: Ako klamný
        feminizmus poškodzuje našich mladých mužov</em> tvrdí, že mužskosť sa stáva terčom útokov. Ako príklad uviedla
        newyorskú Strednú leteckú školu v Queense, ktorá&nbsp; prijíma predovšetkým študentov z nízkopríjmových rodín.
        Škola sa špecializuje na výučbu konštrukcie a funkcie lietadiel prostredníctvom praktických projektov. Študenti
        tu dosahujú vysokú úroveň akademických výsledkov a škola má podľa správy US News &amp; World Report vysoké
        hodnotenia. Väčšinu triedy tvoria chlapci. Dievčatá, hoci tvoria menšie percento, tiež vykazujú pozoruhodné
        výsledky a získali si úctu svojich rovesníkov a učiteľov.</p>
      <p>Stredná letecká škola však čelila rastúcej kritike a hrozbám súdnych sporov zo strany feministických
        organizácií, ktoré požadovali, aby prijímala viac študentiek. Zakladateľka Národného centra pre práva žien
        (National Women’s Law Center) vystúpila v roku 2010 na diskusii za okrúhlym stolom v Bielom dome a špecificky
        zmienila Strednú leteckú školu ako „jasný príklad pokračujúcej segregácie na stredných priemyselných školách“.
        Predseda Rady Bieleho domu pre ženy a dievčatá ukončil diskusiu so slovami: „Nemôžeme odpočívať na vavrínoch,
        kým nebudeme mať absolútnu rovnosť, k tomu sme ale ešte nedospeli“.<a href="#_edn36" id="_ednref36">[36]</a></p>
      <p>Vychovávanie chlapcov, aby sa usilovali o mužské rysy nezávislosti a dobrodružstva, a povzbudzovanie dievčat k
        tomu, aby boli jemné, ohľaduplné a&nbsp;oddané rodine, nie je pre feministky nič viac než útlak
        a&nbsp;sexistická nerovnosť. Moderný feminizmus vedie spoločnosť do budúcnosti bez pohlavia tým, že napáda
        psychologické vlastnosti mužov a žien, ktoré sú charakteristické pre ich pohlavie. To má obzvlášť závažné
        dôsledky pre deti a mladých ľudí, ktorí sú v rokoch, kedy sa formuje ich osobnosť.</p>
      <p>V niektorých európskych krajinách čoraz viac detí uvádza pocit, že sa narodili v nesprávnom tele. V roku 2009
        dostala Služba rozvoja rodovej identity (GIDS), so sídlom v londýnskej štátnej nemocnici Tavistock and Portman
        NHS Foundation Trust,&nbsp; 97 žiadostí týkajúcich sa zmeny pohlavia. V roku 2017 spoločnosť GIDS dostávala viac
        ako 2 500 takýchto žiadostí ročne.<a href="#_edn37" id="_ednref37">[37]</a></p>
      <p>Tradičné spoločnosti považujú pôrod a výchovu detí za posvätnú povinnosť žien, ktorú im určil Boh či nebesia. V
        kronikách Východu aj Západu nájdeme príbehy, kde za každým veľkým hrdinom je úžasná matka. Feminizmus túto
        tradíciu odmieta ako patriarchálny útlak a tvrdí, že kľúčovým príkladom takéhoto útlaku je očakávanie, že ženy
        budú zodpovedné za výchovu svojich detí. Súčasná feministická literatúra je plná zobrazenia materstva a
        manželského života ako monotónneho, nudného a nenaplneného. Predpojatosť tohto pesimistického názoru je zrejmá,
        ak prihliadneme k osobnému životu vedúcich feministiek. Takmer všetky majú za sebou rozbitý vzťah či prešli
        neúspešnými manželstvami alebo sú bezdetné.</p>
      <p>Radikálne feministické názory trvajú na tom, že „čo je osobné je politické“ a považujú konflikty v domácnosti
        za vojnu pohlaví. Niektoré považujú mužov za parazitov, ktorí zotročujú ženské telo a myseľ. Iní popisujú deti
        ako prekážku pre ženy, ktoré chcú dosiahnuť svoj plný potenciál, a tvrdia, že korene útlaku sú v štruktúre
        rodiny. Moderný feminizmus otvorene vyhlasuje, že jeho cieľom je zničiť tradičnú rodinu. Medzi typické
        vyhlásenia patrí: „Byť v domácnosti je nelegitímna profesia. ... Voľba slúžiť a byť chránená a starať sa o
        rodinu je voľba, ktorá by nemala existovať. Srdcom radikálneho feminizmu je zmeniť to,“<a href="#_edn38"
                                                                                                  id="_ednref38">[38]</a> a
        „Nedokážeme zničiť nerovnosti medzi mužmi a ženami, kým nezničíme manželstvo.“<a href="#_edn39"
                                                                                         id="_ednref39">[39]</a></p>
      <p>Feministické hnutia v mene „oslobodenia“ riešili takzvané sociálne problémy podporou morálnej degenerácie a
        ničením medziľudských vzťahov. Podľa americkej ekonómky a rodovej špecialistky Sylvie Ann Hewlettovej je moderný
        feminizmus hlavným faktorom, ktorý prispieva k šíreniu domácností s jedným rodičom – (zvyčajne matkou), zatiaľ
        čo rýchly rozvod vlastne predstavuje pre mužov vhodný prostriedok na zbavenie sa&nbsp; zodpovednosti. Je
        iróniou, že útok feminizmu na existujúcu štruktúru rodiny vlastne ničí útočisko, ktoré zaisťuje šťastie a
        bezpečnosť väčšiny žien.</p>
      <p>Rýchle rozvody ženy neemancipovali. Štúdia z roku 2009 na Londýnskej ekonomickej škole (London School of
        Economics) zistila, že 27 % žien, ktoré žili v domácnosti bez muža, žilo pod hranicou chudoby, zatiaľ čo príjem
        mužov po odlúčení mal tendenciu stúpať o viac ako 30 %.<a href="#_edn40" id="_ednref40">[40]</a> Komunizmus sa
        vôbec nezaujíma o práva žien; feminizmus je iba nástrojom na zničenie ľudstva.</p>
      <h3>c) Propagácia homosexuality kvôli podkopaniu rodiny</h3>
      <p>Muž a žena boli stvorení na obraz boží a Boh stanovil podmienky pre ľudskú existenciu. Každý si zaslúži
        láskavosť a rešpekt a skutočný súcit znamená dodržiavať morálne pravidlá stanovené Bohom.</p>
      <p>V posledných desaťročiach sa v západnej spoločnosti agresívne propagovali manželstvá osôb rovnakého pohlavia a
        ďalšie lesbické, homosexuálne, bisexuálne a transsexuálne (LGBT) záležitosti. Hnutie LGBT je úzko spojené s
        komunizmom už od chvíle, keď prví utopisti začali propagovať homosexualitu ako ľudské právo. Keďže komunistické
        hnutie tvrdí, že oslobodzuje ľudí z otroctva tradičnej morálky, jeho ideológia prirodzene vyžaduje, aby sa práva
        LGBT stali súčasťou jeho programu „sexuálneho oslobodenia“.&nbsp; Mnoho zástancov sexuálneho oslobodenia, ktorí
        neochvejne podporujú homosexualitu, sú komunisti alebo ľavičiari. Spojením práv LGBT a sexuálneho oslobodenia,
        teda vo všeobecnosti normalizáciou promiskuity, komunisti podkopali posvätnosť manželstva. Komunizmus sa v
        skutočnosti nezaujíma o práva LGBT komunity. Využíva obhajovanie práv LGBT ako prostriedok pre svoj vlastný cieľ
        – zničenie rodinnej štruktúry.</p>
      <p>Prvá väčšia organizácia pre práva homosexuálov bola založená v roku 1897 členmi Sociálnodemokratickej strany
        Nemecka (SPD), vrátane&nbsp; Magnusa Hirschfelda, spoluzakladateľa Vedecko-humanitárneho výboru známeho v
        nemčine ako Wissenschaftlich-humanitäres Komitee (WhK).&nbsp; Hirschfeld verejne bojoval za dekriminalizáciu
        homosexuality.</p>
      <p>Jeden z najradikálnejších príkladov sexuálneho oslobodenia sa udial v ére po boľševickej októbrovej revolúcii v
        roku 1917. Sovietska sexuálna politika, o ktorej sa v tejto kapitole diskutovalo skôr, zrušila zákaz
        homosexuálnych vzťahov a urobila zo Sovietskeho zväzu podľa ľavicových štandardov najliberálnejšiu krajinu na
        zemi.</p>
      <p>V roku 1924, inšpirovaný Hirschfeldovou WhK, založil Henry Gerber prvú americkú organizáciu pre práva
        homosexuálov, Spoločnosť pre ľudské práva. Organizácia bola rozpustená nasledujúci rok po zatknutí niekoľkých
        jej členov. V roku 1950 založil americký komunistický a marxistický učiteľ Harry Hay, spoločnosť Mattachine
        Society vo svojom sídle v Los Angeles. Táto organizácia sa stala prvou vplyvnou skupinou pre práva homosexuálov
        v Spojených štátoch. Vydala vlastné publikácie a rozšírila sa do ďalších miest. Hay tiež obhajoval
        pedofíliu.</p>
      <p>Homosexualita sa stala „vecou verejnou“ v 60. rokoch 20. storočia, kedy bola zároveň v plnom prúde kampaň za
        sexuálne oslobodenie a hnutie hippies. V roku 1971 Národná organizácia pre ženy (NOW) prijala rezolúciu, v
        ktorej uznala, že „lesbické práva sú legitímnym záujmom feminizmu“.</p>
      <p>Africký národný kongres (ANC) Južnej Afriky schválil v roku 1997 prvú ústavu na svete, ktorá uznala
        homosexualitu ako ľudské právo. ANC je členom Socialistickej internacionály (čo je bývalá pobočka dnes už
        zaniknutej druhej internacionály) a homosexualitu dôsledne podporuje.</p>
      <p>Komunistická obhajoba homosexuality prispela k nárastu mnohých nezdravých javov. Štúdia vedcov z Centier pre
        kontrolu a prevenciu chorôb USA zistila, že odhadovaná miera diagnostikovania HIV medzi homosexuálmi v roku 2008
        bola 59 až 75-krát vyššia ako u ostatných mužov; miera diagnózy syfilis bola 63 až 79-krát vyššia ako u
        heterosexuálnej populácie.<a href="#_edn41" id="_ednref41">[41]</a> Pred dosiahnutím prielomov v liečbe AIDS v
        90. rokoch bola priemerná dĺžka života 20-ročných homosexuálov o osem až dvadsaťjeden rokov kratšia ako
        priemernej populácie.<a href="#_edn42" id="_ednref42">[42]</a></p>
      <p>Rodinná štruktúra a ľudská morálka boli napadnuté duchom komunizmu a jeho manipuláciou a podporou feminizmu,
        sexuálneho oslobodenia a LGBT hnutia.</p>
      <h3>d) Podpora rozvodov a potratov</h3>
      <p>Pred rokom 1969, keď štáty začali legalizovať rozvody „bez zavinenia“, boli štátne zákony v Spojených štátoch
        založené na tradičných náboženských hodnotách. Aby sa rozvod uskutočnil,&nbsp; vyžadovalo sa legitímne tvrdenie
        o&nbsp;zavinení zo strany jedného alebo oboch manželov. Západné náboženstvá učia, že manželstvo ustanovuje Boh.
        Stabilná rodina je prospešná pre manžela, manželku, deti a celú spoločnosť. Z toho dôvodu cirkev a zákony štátov
        USA zdôrazňovali význam zachovania manželstva, pokiaľ tam neexistovali špeciálne okolnosti.</p>
      <p>Do spoločnosti sa však v 60. rokoch 20. storočia rozšírili ideológie Frankfurtskej školy. Tradičné manželstvo
        bolo napadnuté a najväčšie škody napáchal liberalizmus a feminizmus. Liberalizmus odmieta božskú podstatu
        manželstva tým, že obmedzuje jeho definíciu na spoločenskú zmluvu medzi dvomi ľuďmi, pričom feminizmus považuje
        tradičnú rodinu za patriarchálny nástroj na utláčanie žien. Rozvod bol propagovaný ako oslobodenie ženy od
        útlaku nešťastného manželstva alebo ako cesta k vzrušujúcemu životu dobrodružstva. Takéto názory viedli k
        legalizácii rozvodu „bez zavinenia“, čo umožnilo manželovi alebo manželke, aby mohli bez udania dôvodu rozviazať
        manželstvo ako neudržateľné.</p>
      <p>Miera rozvodovosti v USA rýchlo vzrástla v 70. rokoch 20. storočia a dosiahla vrchol v roku 1981. Prvýkrát v
        americkej histórii bolo viac manželstiev ukončených nie úmrtím jedného z manželov, ale kvôli nezhodám. Zo
        všetkých novomanželských párov sa v 70. rokoch takmer polovica rozviedla, v porovnaní asi s 11 % v päťdesiatych
        rokoch. Obhajcovia sexuálneho oslobodenia sa domnievajú, že sex by sa nemal obmedzovať iba na manželstvo, ale
        nežiadúce tehotenstvo predstavuje prirodzenú prekážku pre takýto druh životného štýlu. Antikoncepcia môže
        zlyhať, takže propagátori neobmedzeného sexu sa ujali agendy legalizácie potratov. Oficiálna správa z
        Medzinárodnej konferencie OSN o populácii a rozvoji v Káhire z roku 1994 uvádza, že reprodukčné zdravie
        „znamená, že ľudia sú schopní mať uspokojivý a bezpečný sexuálny život a že majú schopnosť rozmnožovať sa a
        slobodu rozhodnúť či, kedy a ako často tak urobiť.“<a href="#_edn43" id="_ednref43">[43]</a></p>
      <p>Feministky v tom čase zároveň predstavili slogan „moje telo, moje práva“ pričom tvrdili, že je na ženách, aby
        si vybrali, či budú rodiť alebo prostredníctvom potratu zabijú svoje nenarodené dieťa. Diskusia sa rozšírila od
        povolenia potratov za osobitných okolností na poskytnutie právomoci ženám jednostranne ukončiť ľudský život z
        osobných dôvodov.</p>
      <p>Schvaľovaním potratov sa ľudia dopúšťajú vrážd detí. Zároveň sa odkláňajú od tradičného chápania, že pohlavný
        styk slúži na rozmnožovanie sa.</p>
      <h3>e) Využívanie systému sociálnej starostlivosti na podporu rodín s jedným rodičom</h3>
      <p>V roku 1960 sa len 5 % zo všetkých narodených detí narodilo slobodným matkám. V tom čase sa považovalo za
        samozrejmé, že deti vyrastali s vedomím, kto je ich biologický otec.</p>
      <p>V roku 2010 však počet narodených detí slobodným matkám predstavoval už 40 %.<a href="#_edn44"
                                                                                         id="_ednref44">[44]</a>&nbsp; Od
        roku 1965 do roku 2012 sa počet neúplných rodín v Amerike zvýšil z 3,3 milióna na 13,2 milióna.<a href="#_edn45"
                                                                                                          id="_ednref45">[45]</a>&nbsp; Hoci
        niektorí otcovia zostali zapojení do výchovy, či už v rámci spolužitia alebo neskoršieho sobáša so ženou,
        väčšina detí narodených týmto slobodným matkám vyrastala bez otca.</p>
      <p>Otcovia slúžia ako vzory svojim synom tým, že ich učia, ako byť mužom, a ukazujú svojim dcéram, aké to je byť
        rešpektovaná tak, ako si žena zaslúži. Neprítomnosť otca znášajú deti veľmi ťažko. Výskumy ukazujú, že deti,
        ktoré vyrastali bez otca, často trpia nízkou sebaúctou. Je u&nbsp;nich &nbsp;pravdepodobnejšie, že prestanú
        chodiť do školy, začnú užívať drogy, stanú sa členmi rôznych gangov, budú páchať zločiny, či spáchajú
        samovraždu. Väčšina uväznených mladistvých pochádza z domácností bez otca. Bežné sú aj skoré sexuálne
        skúsenosti, tehotenstvo mladistvých dievčat a promiskuita. U ľudí, ktorí vyrastali bez otcov, je 40-krát vyššia
        pravdepodobnosť páchania sexuálnych trestných činov v porovnaní so zvyškom populácie.<a href="#_edn46"
                                                                                                id="_ednref46">[46]</a>
      </p>
      <p>Americký výskumný ústav the Brookings institution ponúka mladým ľuďom, ktorí sa snažia uniknúť z chudoby, tri
        kľúčové rady: Dokončiť strednú školy, získať zamestnanie na plný úväzok a počkať s deťmi a svadbou do veku 21
        rokov. Podľa štatistík iba 2% Američanov, ktorí splnia tieto podmienky, žijú v chudobe a 75% sa považuje za
        strednú triedu.<a href="#_edn47" id="_ednref47">[47]</a> Inými slovami, je to najspoľahlivejšia cesta, ako sa
        stať zodpovedným dospelým, ktorý žije zdravým a produktívnom životom.</p>
      <p>Väčšina slobodných matiek sa spolieha na vládnu pomoc. Správa uverejnená nadáciou Heritage Foundation použila
        podrobné štatistické údaje, aby ukázala, že sociálna politika tak silne presadzovaná feministkami v skutočnosti
        podporuje vytváranie domácností so slobodnými matkami, dokonca až do takej miery, že trestá páry, ktoré sa
        zoberú, pretože tí majú nárok na menšie dávky.<a href="#_edn48" id="_ednref48">[48]</a> Vláda prakticky
        nahradila otca sociálnymi dávkami.</p>
      <p>Politika sociálneho zabezpečenia rodinám žijúcim v chudobe nepomohla. Naopak, namiesto toho podporila neustále
        rastúci počet neúplných rodín. Deti z takýchto rodín sú samy v dospelom veku náchylnejšie k chudobe, a tak je
        výsledkom začarovaný kruh rozširujúcej sa závislosti od štátnej pomoci. To je presne to, čo sa komunizmus snaží
        dosiahnuť: kontrolu nad všetkými aspektmi života jednotlivca.</p>
      <h3>f) Propagácia degenerovanej kultúry</h3>
      <p>V roku 2000 žilo 55 % ľudí vo veku 25 až 34 rokov v manželstve a 34 percent sobáš ešte nikdy neuzavrelo. Do
        roku 2015 sa tieto čísla takmer obrátili, v pomere 40 % ku 53 %. Výskumníci študujúci tento trend na Texaskej
        univerzite v Austine zistili, že mladí ľudia v Spojených štátoch sa vyhýbali manželstvu, pretože v dnešnej
        kultúre sa sex a manželstvo považujú za dve rozdielne veci. Takže prečo by sa mali vziať?<a href="#_edn49"
                                                                                                    id="_ednref49">[49]</a>
      </p>
      <p>V tomto degenerovanom prostredí ide trend smerom k príležitostným a nezáväzným flirtom. Sex nemá nič spoločné s
        citovou blízkosťou, nehovoriac o záväzku a zodpovednosti. Ešte absurdnejšia je záplava nových sexuálnych
        orientácií, ktoré sa už používajú ako módne vyhlásenia. V možnostiach užívateľského profilu Facebooku vo Veľkej
        Británii bolo raz k dispozícii sedemdesiat rôznych typov pohlaví. Ak mladí ľudia ani nedokážu určiť, či sú mužom
        alebo ženou, ako sa asi potom budú pozerať na manželstvo? Komunizmus zneužil zákon a spoločnosť, aby úplne
        prepracoval tieto Bohom dané koncepty.</p>
      <p>Pojem „cudzoložstvo“ býval termínom s negatívnym významom a vzťahoval sa na nemorálne sexuálne správanie. Dnes
        sa zjemnil na „mimomanželské sexuálne vzťahy“ či „partnerské spolužitie“. V klasickom románe <em>Šarlátové
          písmeno</em> od Nathaniela Hawthornea z roku 1850&nbsp; sa hlavná postava Hester Prynneová dopustí
        cudzoložstva a pomocou pokánia sa snaží napraviť. V dnešnej spoločnosti však nie je pokánie potrebné:
        cudzoložníci sa môžu hrdo tešiť zo života a ešte sa tým pýšia. Cudnosť bývala vo východnej i západnej kultúre
        cnosťou. Dnes sa však stala zastaraným vtipom.</p>
      <p>Kvôli politickej korektnosti je akékoľvek posudzovanie homosexuality a sexuálneho správania zakázané. Jediným
        prijateľným postojom je rešpektovať „slobodnú voľbu“ ostatných. To platí nielen v každodennom živote, ale aj v
        celom akademickom prostredí, kde sa morálka oddelila od praktickej reality. Odchýlené a degenerované veci sa
        stali normálnymi. Tí, ktorí sa im oddávajú, necítia žiaden tlak ani vinu.</p>
      <p>Ľudia na Západe mladší ako päťdesiat rokov si sotva môžu spomenúť na kultúru, ktorá existovala v spoločnosti,
        kde takmer všetky deti vyrastali v prítomnosti svojich biologických otcov. Výraz „gay“ pôvodne znamenal byť
        „veselý“. Biele svadobné šaty boli symbolom cudnosti. Pornografický obsah bol v televíznom i rozhlasovom
        vysielaní zakázaný. To všetko bolo zničené za sotva šesťdesiat rokov.</p>
      <h2>5. Ako Čínska komunistická strana ničí rodiny</h2>
      <h3>a) Rozbíjanie rodín v mene rovnoprávnosti</h3>
      <p>Slogan Mao Ce-tunga „Ženy podopierajú polovicu oblohy“ si teraz našiel cestu do západnej spoločnosti ako módna
        feministická fráza. Ideológia, že muži a ženy sú rovnakí, ako ju presadzuje ČKS, sa v podstate nelíši od
        západného feminizmu. Na Západe sa „diskriminácia na základe pohlavia“ používa ako zbraň na udržanie stavu
        „politickej korektnosti“. Označenie „mužský šovinizmus“ má v Číne, aj keď sa tu v praxi líši, podobný
        deštruktívny účinok.</p>
      <p>Rovnosť pohlaví obhajovaná západným feminizmom si vyžaduje rovnosť výsledkov u mužov a žien prostredníctvom
        opatrení, ako sú rodové kvóty, finančné kompenzácie či zníženie štandardov. Podľa sloganu ČKS, že ženy
        podopierajú polovicu oblohy, sa očakáva, že ženy prejavia rovnaké schopnosti v rovnakej práci, akú vykonávajú
        ich mužskí kolegovia. Tie, ktoré sa pokúšali plniť úlohy, na ktoré boli sotva spôsobilé, boli chválené ako
        hrdinky a ocenené titulmi, ako&nbsp; „Držiteľka červenej zástavy 8. marca“. Takéto tituly sa udeľovali moderným
        ženám, ktoré „energicky propagujú základné socialistické hodnoty.“</p>
      <p>Propagandistické plagáty zo 60. a 70. rokov 20. storočia bežne zobrazovali ženy ako fyzicky zdatné a silné,
        zatiaľ čo Mao Ce-tung nadšene vyzýval ženy, aby obrátili svoju lásku k&nbsp;make-upu na lásku k vojenským
        uniformám. Ťažba uhlia a dreva, výroba ocele, bojovanie na bojisku – každý typ práce alebo úlohy im bol k
        dispozícii.</p>
      <p>Čínsky Ľudový denník v článku publikovanom 1. októbra 1966 priniesol príbeh s titulkom „Dievčatá tiež dokážu
        zabíjať ošípané“. Popisoval 18-ročnú mladú ženu, ktorá sa stala miestnou celebritou, keď pracovala na bitúnku.
        Študovanie <em>Myšlienok Mao Ce-tunga </em>jej pomohlo nájsť v sebe odvahu zabíjať prasatá. Vyhlasovala: „Ak
        nedokážete zabiť ani prasa, ako potom chcete zabiť nepriateľa?“<a href="#_edn50" id="_ednref50">[50]</a></p>
      <p>Hoci čínske ženy „podopierajú polovicu oblohy“, feministky na Západe stále v mnohých oblastiach považujú rodovú
        rovnosť v Číne za nedostatočnú. V stálom výbore politbyra ČKS napríklad nie sú zastúpené žiadne ženy kvôli
        obavám, že by to podporilo sociálne hnutie žiadajúce o viac politických práv, ako je napríklad demokracia, ktoré
        by predstavovalo fatálnu hrozbu pre totalitnú vládu strany.</p>
      <p>Z podobných obáv sa strana taktiež vyhýba verejnej podpore homosexuality a zaujíma k tejto otázke relatívne
        neutrálny postoj. Strana však homosexualitu v Číne občas ticho podporila využitím vplyvu médií a populárnej
        kultúry. Médiá diskrétne nahradili hovorový výraz „gay“ slovom „partner“, čo je pojem s pozitívnejším významom.
        Čínska psychiatrická spoločnosť v roku 2001 odstránila homosexualitu zo zoznamu duševných porúch. V roku 2009
        ČKS schválila prvý Shanghai Pride Week.</p>
      <p>Aj keď sa prístupy môžu líšiť, duch komunizmu vždy sleduje rovnaký cieľ: odstrániť tradičný ideál dobrej
        manželky a milujúcej matky, prinútiť ženy opustiť svoj jemný charakter a zničiť harmóniu medzi mužom a ženou,
        ktorá je potrebná na vytvorenie vyváženej rodiny a výchovu dobre pripravených detí.</p>
      <h3>b) Roznecovanie nesvárov medzi manželmi</h3>
      <p>Tradičné čínske hodnoty sú založené na rodinnej morálke. Diabol vie, že najúčinnejší spôsob, ako podkopať
        tradičné hodnoty, je začať so sabotovaním ľudských vzťahov. Členovia rodín sa v nepretržitých politických bojoch
        spustených ČKS navzájom udávali úradom v šialenej súťaži o lepšie politické postavenie. Zradením svojich
        najbližších mohli preukázať pevnejší a lojálnejší postoj v snahe o stranícku čistotu.</p>
      <p>Maov tajomník Chu Čchiao-mu bol v decembri 1966 dovlečený do Pekinského inštitútu&nbsp; výroby železa a ocele,
        kde vystúpila jeho vlastná dcéra a zakričala: „Rozbite Chu Čchiao-muovi jeho psiu hlavu!“ Aj keď sa toho sama
        nezúčastnila, boli tam iní, ktorí to urobili. V tom čase našli Červené gardy istú „kapitalistickú“ rodinu v
        predmestí Tung-s’ v Pekingu. Červené gardy ubili rodičov takmer na smrť a prinútili ich syna, ktorý bol v
        školskom veku, aby činkami rozbil svojmu otcovi hlavu. Syn z toho&nbsp; následne zošalel.<a href="#_edn51"
                                                                                                    id="_ednref51">[51]</a>
      </p>
      <p>Tí, ktorí boli stranou označení za „triednych nepriateľov“, často zavrhli svoje rodiny, aby ich ušetrili pred
        ďalším prenasledovaním. Dokonca aj „triedni nepriatelia“, ktorí spáchali samovraždu, museli najprv prerušiť
        rodinné väzby, aby po ich samovražde nemohla ČKS prenasledovať ich rodinných príslušníkov. V období kultúrnej
        revolúcie bol napríklad literárny teoretik Jie I-čchun prenasledovaný a donútený k samovražde. V jeho liste na
        rozlúčku stálo:&nbsp; „Kráčajúc vpred, jediná vec, ktorá sa od vás vyžaduje, je odhodlane načúvať slovám strany,
        postaviť sa pevne za stranu, postupne rozpoznávať moje hriechy, rozprúdiť nenávisť voči mne a neochvejne
        pretrhnúť naše rodinné väzby.“<a href="#_edn52" id="_ednref52">[52]</a></p>
      <p>V modernej dobe je prenasledovanie duchovnej praxe Falun Gong najväčšou politickou kampaňou, ktorú ČKS
        spustila. Bežnou stratégiou, ktorú orgány strany používajú proti praktizujúcim Falun Gongu, je prinútiť ich
        rodinných príslušníkov, aby napomáhali prenasledovaniu. ČKS využíva administratívne obťažovanie, finančné pokuty
        a iné formy zastrašovania a využíva vplyv členov rodín praktizujúcich, aby na nich tlačili a&nbsp;prinútili ich
        vzdať sa svojej viery. ČKS viní obete prenasledovania z obťažovania ich rodín a tvrdí, že obťažovanie pokračuje
        iba preto, že praktizujúci sa odmieta vzdať svojho presvedčenia. Mnoho praktizujúcich Falun Gongu bolo kvôli
        tejto forme prenasledovania prinútených k rozvodu alebo boli zavrhnutí svojimi blízkymi. Kampaň strany rozbila
        nespočetné množstvo rodín.</p>
      <h3>c) Použitie nútených potratov na riadenie populácie</h3>
      <p>Krátko potom, čo sa západným feministkám podarilo vyhrať boj za legalizáciu potratov, boli pre ženy v Čínskej
        ľudovej republike potraty povinné, a to na základe politiky plánovania rodiny ČKS. Masové zabíjanie nenarodených
        detí malo za následok bezprecedentnú humanitárnu a sociálnu katastrofu.</p>
      <p>ČKS nasleduje marxistický materializmus a verí, že pôrod je forma produktívnej činnosti, ktorá sa nijako nelíši
        od výroby ocele či poľnohospodárstva. Z toho vyplýva, že filozofia ekonomického plánovania by sa mala rozšíriť
        aj na rodinu. Mao vyhlásil: „Ľudstvo sa musí ovládať a zaviesť plánovaný rast. Niekedy sa môže trochu zvýšiť a
        občas sa môže zastaviť.“<a href="#_edn53" id="_ednref53">[53]</a></p>
      <p>V 80. rokoch 20. storočia začal čínsky režim extrémnymi a brutálnymi opatreniami presadzovať politiku jedného
        dieťaťa. Ukazujú to slogany rozmiestnené po celej krajine:&nbsp; „Ak jeden človek poruší zákon, celá dedina bude
        sterilizovaná“, „Poroď prvé (dieťa), nechaj si zviazať vajcovody po druhom a vyškrabať tretie a štvrté!“
        (zjednodušená variácia tohto sloganu znela: „Zabi, zabi, zabi tretie a&nbsp;štvrté“), „Radšej by sme videli prúd
        krvi než príliš veľa narodených“, „Desať ďalších hrobov je lepších ako jeden život navyše“. Takéto krvilačné
        výroky boli všade po celej Číne.</p>
      <p>Národná komisia pre plánovanie zdravia a rodiny použila vysoké pokuty, drancovanie, búranie domov obyvateľov,
        útoky, zadržiavanie a iné podobné tresty na riešenie porušení politiky jedného dieťaťa. V niektorých oblastiach
        úradníci z komisie pre plánovanie rodiny utopili deti tým, že ich hodili do ryžových polí. K potratom boli
        donútené dokonca aj tie ženy, ktorým zostávalo len pár dní do pôrodu.</p>
      <p>V roku 2013 čínske ministerstvo zdravotníctva zverejnilo údaje, ktoré odhaľujú, že od roku 1971 bolo v Číne
        vykonaných najmenej 336 miliónov potratov. Politika jedného dieťaťa sa začala v roku 1979, čo znamená, že za
        viac ako tridsať rokov svojej existencie bolo niekoľko miliónov nenarodených detí každoročne zavraždených
        ČKS.</p>
      <p>Jedným z najzávažnejších dôsledkov politiky jedného dieťaťa v Číne je neprimerane vysoký počet potratených či
        opustených dievčatiek, čo v Číne vedie k vážnej nerovnováhe v pomere mužov a žien mladších 40 rokov. Vzhľadom na
        nedostatok žien sa odhaduje, že do roku 2020 bude asi 30 miliónov mladých mužov, ktorí si nebudú môcť nájsť ženu
        v plodnom veku.</p>
      <p>Umelo vytvorená nerovnováha pohlaví v Číne spôsobila vážne sociálne problémy, ako napríklad nárast sexuálneho
        zneužívania a prostitúcie, manželstiev uzavretých hlavne kvôli finančnému zisku a obchodovanie so ženami.</p>
      <h2>6. Dôsledky útoku komunizmu&nbsp; na rodinu</h2>
      <p>Marx a ďalší komunisti využili javy ako cudzoložstvo, prostitúcia, nemanželské deti, aby poskytli dôveryhodnosť
        svojim anti-manželským a anti-rodinným teóriám – ako keby existencia takýchto nemorálností znamenala, že
        existujúce spoločenské normy sú skorumpované a pokrytecké.</p>
      <p>Postupná degenerácia morálky, ku ktorej došlo vo viktoriánskom období, narušila posvätnú inštitúciu manželstva
        a odviedla ľudí ďalej od božieho učenia. Komunisti vyzývali ženy, aby kvôli údajnému osobnému šťastiu porušili
        manželskú prísahu. Avšak výsledok bol práve opačný.</p>
      <p>Komunistické „riešenie“ útlaku a nerovnosti nie je ničím iným ako zvrhnutím štandardov ľudskej morálky do
        pekelných hĺbok. Správanie, ktoré bolo kedysi všeobecne odsudzované a považované za ohavné a neodpustiteľné, sa
        stalo novou normou. V „rovnosti“ komunizmu kráčajú všetci k rovnakému osudu – zničeniu.</p>
      <p>Duch komunizmu vytvoril mylné presvedčenie, že hriech nie je spôsobený degeneráciou morálky, ale sociálnym
        útlakom. Viedol ľudí k tomu, aby hľadali cestu, ako sa otočiť chrbtom k tradíciám a odklonili sa od božského.
        Použil krásnu rétoriku slobody a oslobodenia na obhajobu feminizmu, homosexuality a sexuálnej zvrátenosti. Ženy
        boli zbavené dôstojnosti, muži zodpovednosti a posvätnosť rodiny bola pošliapaná, čím sa dnešné deti stali
        hračkami v&nbsp;rukách diabla.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Friedrich Engels, <em>Origins of the Family, Private Property, and the
        State („</em><em>Pôvod rodiny, súkromného ​​vlastníctva a štátu<em>“),</em></em> trans. Alick West, (1884),
        chap. 2, part 4, Marxists Internet Archive, zo 17. apríla 2020, <a
          href="https://www.marxists.org/archive/marx/works/1884/origin-family/ch02d.htm"></a><a
          href="https://www.marxists.org/archive/marx/works/1884/origin-family/ch02d.htm">https://www.marxists.org/archive/marx/works/1884/origin-family/ch02d.htm</a>.
      </p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> W. Bradford Wilcox, „The Evolution of Divorce,“ <em>National
        Affairs,</em> no. 1 (Fall 2009), <a
        href="https://www.nationalaffairs.com/publications/detail/the-evolution-of-divorce"></a><a
        href="https://www.nationalaffairs.com/publications/detail/the-evolution-of-divorce">https://www.nationalaffairs.com/publications/detail/the-evolution-of-divorce</a>.
      </p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> US Centers for Disease Control and Prevention, National Center for
        Health Statistics („Centrum USA pre kontrolu a prevenciu chorôb: Národné centrum pre štatistiku v oblasti
        zdravia“), „Table 1–17. Number and Percent of Births to Unmarried Women, by Race and Hispanic Origin: United
        States, 1940–2000,“ („Tabuľka 1–17. Počet a percento narodení detí u slobodných žien podľa rasy a hispánskeho
        pôvodu: Spojené štáty, 1940 – 2000, ”)&nbsp; <a href="https://www.cdc.gov/nchs/data/statab/t001x17.pdf"></a><a
          href="https://www.cdc.gov/nchs/data/statab/t001x17.pdf">https://www.cdc.gov/nchs/data/statab/t001x17.pdf</a>.
      </p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> John Elflein, “Percentage of births to unmarried women in the US from
        1980 to 2018,” <em>(</em><em>„Percento narodení detí u nezosobášených žien v USA v rokoch 1980 až
          2018“)</em> Statista, 3. decembra 2019, <a
          href="https://www.statista.com/statistics/276025/us-percentage-of-births-to-unmarried-women/"></a><a
          href="https://www.statista.com/statistics/276025/us-percentage-of-births-to-unmarried-women/">https://www.statista.com/statistics/276025/us-percentage-of-births-to-unmarried-women/</a>.
      </p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Genesis 2:23, American Standard Version Bible.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Robert Owen, “Critique of Individualism (1825–1826),” („Kritika
        individualizmu (1825 – 1826)“) Indiana University–Bloomington, July 4, 1826, zo 17. apríla 2020. <a
          href="https://web.archive.org/web/20171126034814/http:/www.indiana.edu:80/~kdhist/H105-documents-web/week11/Owen1826.html"></a><a
          href="https://web.archive.org/web/20171126034814/http">https://web.archive.org/web/20171126034814/http</a>://www.indiana.edu:80/~kdhist/H105-documents-web/week11/Owen1826.html.
      </p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Engels, Origins, kap. 2.</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Alexander Melnichenko Александр Мельниченко, „Velikaya oktyabyr’skaya
        seksual’naya revolyutsiya” Великая октябрьская сексуальная революция („Veľká októbrová sexuálna revolúcia“),
        Russian Folk Line, 20. augusta 2017, <a
          href="http://ruskline.ru/opp/2017/avgust/21/velikaya_oktyabrskaya_seksualnaya_revolyuciya"></a><a
          href="http://ruskline.ru/opp/2017/avgust/21/velikaya_oktyabrskaya_seksualnaya_revolyuciya">http://ruskline.ru/opp/2017/avgust/21/velikaya_oktyabrskaya_seksualnaya_revolyuciya</a>.
        (v ruštine)</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Madame Smidovich Смидович, citovaná v Natal’ya Korotkaya Наталья
        Короткая, Эрос революции: „Комсомолка, не будь мещанкой — помоги мужчине снять напряжение! („Eros revolúcie:
        ‘Komsomoľské dievča, nebuď buržujka — pomôž mužovi uvoľniť napätie!’”), Tut.By Online, November 10, 2012, <a
          href="https://lady.tut.by/news/sex/319720.html?crnd=68249.">https://lady.tut.by/news/sex/319720.html?crnd=68249.</a> (v
        ruštine)</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Paul Kengor, <em>Takedown: From Communists to Progressives, How the
        Left Has Sabotaged Family and Marriage</em> („Od komunistov k progresívcom, ako ľavica sabotovala rodinu a
        manželstvo“), (Washington, DC: WND Books, 2015), 54.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Melnichenko, „The Great.“</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Xia Hou 夏侯, „Gongchanzhuyi de yinluan jiyin—xingjiefang“
        共产主义的淫乱基因——性解放 („Promiskuitný gén komunizmu: Sexuálne oslobodenie“), The Epoch Times (čínske vydanie), 9. apríla
        2017, <a href="http://www.epochtimes.com/gb/17/4/9/n9018949.htm"></a><a
          href="http://www.epochtimes.com/gb/17/4/9/n9018949.htm">http://www.epochtimes.com/gb/17/4/9/n9018949.htm</a>.
        (v čínštine)</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Clara Zetkin, „Lenin on the Women’s Question,“ („Lenin o otázke
        žien“) <em>The Emancipation of Women: From the Writings of V. I. Lenin, (</em>„Emancipácia žien: z písomností V.
        I. Lenina“<em>“)</em> Marxists Internet Archive, zo 17. apríla 2020, <a
          href="https://www.marxists.org/archive/zetkin/1920/lenin/zetkin1.htm"></a><a
          href="https://www.marxists.org/archive/zetkin/1920/lenin/zetkin1.htm">https://www.marxists.org/archive/zetkin/1920/lenin/zetkin1.htm</a>.
      </p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Huang Wenzhi 黃文治, „‘Nuola zou hou zen yang’: Funü jiefang,
        hunyinziyou ji jiejigeming—yi E Yu Wan Suqu wei zhongxin de lishikaocha (1922~1932)“
        娜拉走後怎樣”：婦女解放、婚姻自由及階級革命——以鄂豫皖蘇區為中心的歷史考察（1922～1932） („‚Čo sa stalo potom, ako Nora odišla‘: Oslobodenie žien,
        sloboda manželstva a&nbsp;triedna revolúcia – historická štúdia sovietskych oblastí Hubei-Henan-Anhui
        (1922–1932)“) Open Times no. 4 (2013). Tento zdroj čerpá informácie z E Yu Wan Suqu geming lishi wenjianhuiji
        鄂豫皖苏区革命历史文件汇集, (Zbierka revolučných historických dokumentov v sovietskych oblastiach Hubei-Henan-Anhui), (v
        čínštine)</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Judith A. Reisman et al., <em>Kinsey, Sex and Fraud: The
        Indoctrination of a&nbsp;People („</em>Sex a podvody: Indoktrinácia ľudí<em>“)</em> (Lafayette, LA:
        Lochinvar-Huntington House, 1990).</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> Lawrence B. Finer, „Trends in Premarital Sex in the United States,
        1954–2003,“ („Trendy v predmanželskom sexe v Spojených štátoch, 1954 – 2003“) <em>Public Health
          Reports,</em> vol. 122, issue.1 (1. januára 2007): 73–78.</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Nicholas H. Wolfinger, „Counterintuitive Trends in the Link Between
        Premarital Sex and Marital Stability,“ („Neočakávané trendy v&nbsp;spojeniach medzi predmanželským sexom
        a&nbsp;stabilitou manželstva“) Institute for Family Studies, zo 6. júna 2016, <a
          href="https://ifstudies.org/blog/counterintuitive-trends-in-the-link-between-premarital-sex-and-marital-stability.">https://ifstudies.org/blog/counterintuitive-trends-in-the-link-between-premarital-sex-and-marital-stability.</a>
      </p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Betty Friedan, The Feminine Mystique (New York: W.W.
        Norton &amp; Company, 1963).</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Joanne Boucher, „Betty Friedan and the Radical Past of Liberal
        Feminism,“ New Politics, vol. 9, no.3 (Summer 2003).</p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> David Horowitz, „Betty Friedan’s Secret Communist Past,“ Salon, 19.
        január 1999, <a
          href="https://www.salon.com/1999/01/18/nc_18horo.">https://www.salon.com/1999/01/18/nc_18horo.</a></p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> Kate Weigand, Red Feminism: American Communism and the Making of
        Women’s Liberation (Baltimore, MD, and London: The Johns Hopkins University Press, 2002).</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Simone de Beauvoir, The Second Sex, trans. Constance Borde and Sheila
        Malovany-Chevallier (New York: Vintage Books, 2011).</p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> Lawrence Summers, „Harvard President Summers’ Remarks About Women in
        Science, Engineering,“ PBS NewsHour, 22. februára 2005, <a
          href="https://www.pbs.org/newshour/science/science-jan-june05-summersremarks_2-22.">https://www.pbs.org/newshour/science/science-jan-june05-summersremarks_2-22.</a>
      </p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> Alan Finder, „Harvard Will Spend $50 Million to Make Faculty More
        Diverse,“ The New York Times, 17. mája 2005, <a
          href="https://www.nytimes.com/2005/05/17/education/harvard-will-spend-50-million-to-make-faculty-more-diverse.html.">https://www.nytimes.com/2005/05/17/education/harvard-will-spend-50-million-to-make-faculty-more-diverse.html.</a>
      </p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> C. P. Benbow and J. C. Stanley, „Sex Differences in Mathematical
        Ability: Fact or Artifact?“ Science, 210, issue 4475 (december 1980): 1262–1264, <a
          href="https://science.sciencemag.org/content/210/4475/1262.">https://science.sciencemag.org/content/210/4475/1262.</a>
      </p>
      <p><a href="#_ednref30" id="_edn30">[30]</a> Camilla Persson Benbow, „Sex Differences in Ability in Intellectually
        Talented Preadolescents: Their Nature, Effects, and Possible Causes,“ Behavioral and Brain Sciences 11, no. 2
        (jún 1988): 169–183. <a
          href="https://www.cambridge.org/core/journals/behavioral-and-brain-sciences/article/sex-differences-in-mathematical-reasoning-ability-in-intellectually-talented-preadolescents-their-nature-effects-and-possible-causes/C0BC8628A056CB9B38A3464D2DF5FA44.">https://www.cambridge.org/core/journals/behavioral-and-brain-sciences/article/sex-differences-in-mathematical-reasoning-ability-in-intellectually-talented-preadolescents-their-nature-effects-and-possible-causes/C0BC8628A056CB9B38A3464D2DF5FA44.</a>
      </p>
      <p><a href="#_ednref31" id="_edn31">[31]</a> Camilla Persson Benbow et al, „Sex Differences in Mathematical
        Reasoning Ability at Age 13: Their Status 20 Years Later,“ Psychological Science 11, no. 6 (november 2000):
        474-480, <a
          href="https://my.vanderbilt.edu/smpy/files/2013/02/SexDiffs.pdf.">https://my.vanderbilt.edu/smpy/files/2013/02/SexDiffs.pdf.</a>
      </p>
      <p><a href="#_ednref32" id="_edn32">[32]</a> Alexis de Tocqueville, as quoted in Friedrich A. Hayek, The Road to
        Serfdom (London: Profile Books, 2005), 47.</p>
      <p><a href="#_ednref33" id="_edn33">[33]</a> Susan Edelman, „Woman to Become NY Firefighter Despite Failing
        Crucial Fitness Test,“ The New York Post, 3. mája 2015. <a
          href="https://nypost.com/2015/05/03/woman-to-become-ny-firefighter-despite-failing-crucial-fitness-test.">https://nypost.com/2015/05/03/woman-to-become-ny-firefighter-despite-failing-crucial-fitness-test.</a>
      </p>
      <p><a href="#_ednref34" id="_edn34">[34]</a> Una Butorac, „These Female Firefighters Don’t Want a Gender Quota
        System,“ Special Broadcasting Service, 24. marca 2017, <a
          href="https://www.sbs.com.au/news/the-feed/these-female-firefighters-don-t-want-a-gender-quota-system.">https://www.sbs.com.au/news/the-feed/these-female-firefighters-don-t-want-a-gender-quota-system.</a>
      </p>
      <p><a href="#_ednref35" id="_edn35">[35]</a> Commonwealth Court of Pennsylvania, Commonwealth of Pennsylvania by
        Israel Packel, Attorney General, v. Pennsylvania Interscholastic Athletic Association, 334A.2d 839, 18 Pa.
        Commw. 45 (19. marca 1975).</p>
      <p><a href="#_ednref36" id="_edn36">[36]</a> Christina Hoff Sommers, The War Against Boys: How Misguided Feminism
        Is Harming Our Young Men (New York: Simon &amp; Schuster, 2001).</p>
      <p><a href="#_ednref37" id="_edn37">[37]</a> Jamie Doward, „‚Take These Children Seriously‘: NHS Clinic in the Eye
        of Trans Rights Storm,“ The Guardian, November 18, 2017, <a
          href="https://www.theguardian.com/society/2017/nov/19/nhs-clinic-trans-rights-storm-gender-identity-specialist-centre-transgender">https://www.theguardian.com/society/2017/nov/19/nhs-clinic-trans-rights-storm-gender-identity-specialist-centre-transgender</a>
      </p>
      <p><a href="#_ednref38" id="_edn38">[38]</a> Vivian Gornick, as quoted in The Daily Illini, University of
        Illinois, 25. apríla 1981.</p>
      <p><a href="#_ednref39" id="_edn39">[39]</a> Robin Morgan, ed., Sisterhood Is Powerful: An Anthology of Writings
        From the Women’s Liberation Movement (New York: Vintage Books, 1970), 537.</p>
      <p><a href="#_ednref40" id="_edn40">[40]</a> Darlena Cunha, „The Divorce Gap,“ The Atlantic, 28. apríla 2016, <a
        href="https://www.theatlantic.com/business/archive/2016/04/the-divorce-gap/480333.">https://www.theatlantic.com/business/archive/2016/04/the-divorce-gap/480333.</a>
      </p>
      <p><a href="#_ednref41" id="_edn41">[41]</a> David W. Purcell et al., „Estimating the Population Size of Men Who
        Have Sex with Men in the United States to Obtain HIV and Syphilis Rates,“ Open AIDS Journal 6 (september 2012):
        98–107, <a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3462414.">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3462414.</a>
      </p>
      <p><a href="#_ednref42" id="_edn42">[42]</a> R. S. Hogg et al., „Modelling the Impact of HIV Disease on Mortality
        in Gay Men,“ International Journal of Epidemiology 26, no. 3 (jún 1997): 657–661.</p>
      <p><a href="#_ednref43" id="_edn43">[43]</a> United Nations, „Programme of Action of the International Conference
        on Population and Development,“ The International Conference on Population and Development (ICPD) in Cairo,
        Egypt (New York: United Nations, 1995), 59, <a
          href="https://www.un.org/en/development/desa/population/events/pdf/expert/27/SupportingDocuments/A_CONF.171_13_Rev.1.pdf.">https://www.un.org/en/development/desa/population/events/pdf/expert/27/SupportingDocuments/A_CONF.171_13_Rev.1.pdf.</a>
      </p>
      <p><a href="#_ednref44" id="_edn44">[44]</a> The Vice Chairman’s Staff of the Joint Economic Committee at the
        Request of Senator Mike Lee, „Love, Marriage, and the Baby Carriage: The Rise in Unwed Childbearing,“ Social
        Capital Project no. 3–17 (december 2017), <a
          href="https://www.lee.senate.gov/public/_cache/files/3a6e738b-305b-4553-b03b-3c71382f102c/love-marriage-and-the-baby-carriage.pdf.">https://www.lee.senate.gov/public/_cache/files/3a6e738b-305b-4553-b03b-3c71382f102c/love-marriage-and-the-baby-carriage.pdf.</a>
      </p>
      <p><a href="#_ednref45" id="_edn45">[45]</a> Robert Rector, „How Welfare Undermines Marriage and What to Do About
        It,“ The Heritage Foundation, 17. novembra 17, 2014, <a
          href="https://www.heritage.org/welfare/report/how-welfare-undermines-marriage-and-what-do-about-it.">https://www.heritage.org/welfare/report/how-welfare-undermines-marriage-and-what-do-about-it.</a>
      </p>
      <p><a href="#_ednref46" id="_edn46">[46]</a> Phyllis Schlafly, Who Killed The American Family? (Washington, DC:
        WND Books, 2014), chap. 1.</p>
      <p><a href="#_ednref47" id="_edn47">[47]</a> Ron Haskins, „Three Simple Rules Poor Teens Should Follow to Join the
        Middle Class,“ Brookings Institution, 13. marec 2013, <a
          href="https://www.brookings.edu/opinions/three-simple-rules-poor-teens-should-follow-to-join-the-middle-class.">https://www.brookings.edu/opinions/three-simple-rules-poor-teens-should-follow-to-join-the-middle-class.</a>
      </p>
      <p><a href="#_ednref48" id="_edn48">[48]</a> Rector, „How Welfare Undermines.“</p>
      <p><a href="#_ednref49" id="_edn49">[49]</a> Mark Regnerus, „Cheap Sex and the Decline of Marriage,“ The Wall
        Street Journal, 29. septembra&nbsp; 2017, <a
          href="https://www.wsj.com/articles/cheap-sex-and-the-decline-of-marriage-1506690454.">https://www.wsj.com/articles/cheap-sex-and-the-decline-of-marriage-1506690454.</a>
      </p>
      <p><a href="#_ednref50" id="_edn50">[50]</a> Yang Meiling 杨美玲, „Guniang ye neng xuehui sha zhu“ 姑娘也能学会杀猪
        („Dievčatá tiež môžu zabíjať prasce“), Ľudový denník, 1. októbra 1966.</p>
      <p><a href="#_ednref51" id="_edn51">[51]</a> Yu Luowen 遇罗文, Wo jia: wo de gege Yu Luoke 我家：我的哥哥遇罗克 („Moja rodina:
        Môj brat Yu Luoke“), (Beijing: World Chinese Publishing Co., Ltd, 2016).</p>
      <p><a href="#_ednref52" id="_edn52">[52]</a> Ye, Zhou 葉舟, „Ye Yuqin de zuihou shinian“ 葉以群的最後十年 („Posledná dekáda
        Ye Yiqun-a“), mesačník Wenhui, č. 12 (1989).</p>
      <p><a href="#_ednref53" id="_edn53">[53]</a> Pang Xianzhi 逄先知 a Jin Chongji 金冲及, Mao Zedong zhuan (1949–1976)
        毛泽东传(1949–1976) („Životopis Mao Ce-tunga (1949–1976)) (Beijing: Central Party Literature Press, 2003).</p>
      <Link to={`/kapitola_08`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


